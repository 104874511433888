import { Organization, TABLE_HEADER_NAMES } from '@archax/shared-types'
import { Box, Card, Container, Grid, Typography } from '@mui/material'
import { ColDef, GridOptions, RowClickedEvent } from 'ag-grid-community'
import { useNavigate } from 'react-router-dom'
import { getOrganizations } from '../../api/organizations'
import ArchaxLogo from '../../assets/logo.svg'
import { ServerSideGrid } from '../../components/ServerSideGrid'
import { AddButton } from '../../components/ui/AddButton'
import { dateFilterOptions, substringFilterParams } from '../../util/common-grid-options'

function ListOrganizations() {
  const navigate = useNavigate()

  const getLogoSrc = (data: any) => {
    if (data.logoId) return `/api/organizations/${data.id}/logo/${data.logoId}`
    return ArchaxLogo
  }

  const columnDefs: ColDef<Organization>[] = [
    {
      field: 'name',
      headerName: TABLE_HEADER_NAMES.common.name,
      flex: 3,
      sortable: true,
      filter: 'agTextColumnFilter',
      filterParams: substringFilterParams,
      cellRenderer: (params: any) => {
        return (
          <Grid container>
            <img
              src={getLogoSrc(params.data)}
              alt="logo"
              width="34px"
              height="34px"
              onError={(e) => {
                ;(e.target as HTMLImageElement).src = ArchaxLogo
              }}
              style={{
                borderRadius: '6px',
                objectFit: 'cover',
              }}
            />{' '}
            {params.data.name}
          </Grid>
        )
      },
    },
    {
      field: 'created',
      headerName: TABLE_HEADER_NAMES.common.created_at,
      flex: 1,
      sortable: true,
      filter: 'agDateColumnFilter',
      filterParams: dateFilterOptions,
      valueGetter: (params) => {
        return new Date(params.data!.createdAt).toLocaleDateString('en-UK', { hour: '2-digit', minute: '2-digit' })
      },
    },
  ]

  const gridOptions: GridOptions = {
    columnDefs,
    onRowClicked: (params: RowClickedEvent<Organization>) => {
      navigate(`/organizations/${params.data!.id}`)
    },
  }

  return (
    <Container maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Typography align="left" variant="h3">
            Organizations management
          </Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <AddButton
              name="organization-creation"
              ariaLabel="Create a new organization"
              onClick={() => navigate(`/organizations/create`)}
            ></AddButton>
          </Box>
        </Box>
        <Box>
          <ServerSideGrid
            gridOptions={gridOptions}
            queryFn={getOrganizations}
            csvExportUrlGetter={() => '/organizations'}
          />
        </Box>
      </Card>
    </Container>
  )
}

export default ListOrganizations
