"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenStandardErrorMessage = exports.TokenErrorMessage = void 0;
var TokenErrorMessage;
(function (TokenErrorMessage) {
    TokenErrorMessage["NotFound"] = "Token not found";
    TokenErrorMessage["TokenAssetsDownloadNotImplementedForProtocol"] = "Download token assets is not implemented for this token protocol";
})(TokenErrorMessage = exports.TokenErrorMessage || (exports.TokenErrorMessage = {}));
var TokenStandardErrorMessage;
(function (TokenStandardErrorMessage) {
    TokenStandardErrorMessage["NotFound"] = "Token standard not found";
})(TokenStandardErrorMessage = exports.TokenStandardErrorMessage || (exports.TokenStandardErrorMessage = {}));
