import { OperationType } from '@archax/shared-types'
import { Card, CircularProgress, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import EditCreateTokenOperation from './components/EditCreateTokenOperation'
import { getOperation } from '../../api/operations'

function EditOperation() {
  const { id } = useParams()
  const { isLoading, data } = useQuery([`get-operation-${id}`], () => getOperation(id as string), {
    retry: false,
    cacheTime: 0,
  })

  if (!isLoading && !data) {
    // TODO: NotFound reusable component
    return <Typography>Not found</Typography>
  }

  if (data?.data.type === OperationType.CreateToken) {
    return <EditCreateTokenOperation operation={data.data} />
  }

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7 }}>{isLoading && <CircularProgress />}</Card>
    </Container>
  )
}

export default EditOperation
