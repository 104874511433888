import { Currency } from '@archax/shared-types'
import { numericFormatter } from 'react-number-format'

const currencyFormatter = (value: string, options: { prefix?: Currency; decimals: number }): string => {
  const { prefix = '', decimals } = options
  const currencyPrefix = prefix ? `${prefix} ` : ''
  if (!value) {
    return `${currencyPrefix}-`
  }
  const paddedValue = value.padStart(3, '0')
  const decimalString = paddedValue.slice(0, -Number(decimals)) + '.' + paddedValue.slice(-Number(decimals))

  return numericFormatter(decimalString, {
    prefix: `${currencyPrefix}`,
    thousandSeparator: true,
    decimalScale: 2,
    fixedDecimalScale: true,
  })
}
export default currencyFormatter
