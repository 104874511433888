import {
  READABLE_USER_ROLE,
  UserFormData,
  UserRole,
  USER_NAME_MAX_LENGTH,
  USER_NAME_MIN_LENGTH,
} from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { CircularProgress, IconButton, InputAdornment } from '@mui/material'
import Button from '@mui/material/Button'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { getOrganizations } from '../../../api/organizations'
import { getMe } from '../../../api/users'
import Checkbox, { CheckboxOption } from '../../../components/ui/Checkbox/Checkbox'
import Select, { SelectOption } from '../../../components/ui/Select/Select'
import TextField from '../../../components/ui/TextField/TextField'

const adminOrganizationRolesOptions = [
  { value: UserRole.Approver, label: READABLE_USER_ROLE[UserRole.Approver] },
  { value: UserRole.User, label: READABLE_USER_ROLE[UserRole.User] },
]

const allRolesOptions = [
  { value: UserRole.Admin, label: READABLE_USER_ROLE[UserRole.Admin] },
  ...adminOrganizationRolesOptions,
]

interface UserFormProps {
  mode: 'edit' | 'create'
  onSubmit: (data: UserFormData) => void
  defaultValues?: UserFormData
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(USER_NAME_MIN_LENGTH).max(USER_NAME_MAX_LENGTH),
  email: Yup.string().email().required().min(3).max(100),
  roles: Yup.array()
    .of(Yup.string().oneOf(Object.values(UserRole)))
    .min(1, 'Please select at least one role.')
    .required(),
  password: Yup.string().required(),
  organizationId: Yup.string().required(),
})

const initialValues = { name: '', email: '', roles: [] as unknown as UserRole[], password: '', organizationId: '' }

function UserForm({ mode, onSubmit, defaultValues }: UserFormProps) {
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  const [organizationsSelectOptions, setOrganizationsSelectOptions] = useState<SelectOption[] | null>(null)
  const [rolesChecboxOptions, setRolesChecboxOptions] = useState<CheckboxOption[]>(allRolesOptions)

  const { isLoading: isLoadingOrgs, data: organizations } = useQuery(['get-organizations'], () => getOrganizations(), {
    retry: false,
    cacheTime: 0,
    onSuccess({ data }) {
      const organizationsSelectOptions = data.data.map((organization: { id: string; name: string }) => {
        return { value: organization.id, label: organization.name }
      })
      setOrganizationsSelectOptions(organizationsSelectOptions)
    },
  })

  const { isLoading: isLoadingMe, data: me } = useQuery(['get-me'], () => getMe(), { retry: false, cacheTime: 0 })

  const submitButtonLabel = mode === 'create' ? 'Create' : 'Update'

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
    watch,
    setValue,
    getValues,
    trigger,
  } = useForm<UserFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || initialValues,
    resolver: yupResolver(validationSchema),
  })

  const selectedOrganizationId = watch('organizationId')
  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }
    const selectedOrganization = organizations?.data.data.find(
      (organization: { id: string }) => organization.id === selectedOrganizationId,
    )
    if (selectedOrganization.isAdmin) {
      const selectedRolesWithoutAdmin = getValues('roles').filter((role) => role !== UserRole.Admin)
      setValue('roles', selectedRolesWithoutAdmin, { shouldValidate: true })
      setRolesChecboxOptions(adminOrganizationRolesOptions)
    } else {
      setRolesChecboxOptions(allRolesOptions)
    }
  }, [selectedOrganizationId, getValues, organizations?.data.data, setValue])

  const roles = watch('roles')
  useEffect(() => {
    trigger('roles')
  }, [roles, trigger])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoadingOrgs && isLoadingMe && <CircularProgress />}
      {!isLoadingOrgs && !isLoadingMe && organizations && me && organizationsSelectOptions && (
        <>
          <TextField name="name" control={control} label="Name" />
          <TextField name="email" control={control} label="Email" />
          <TextField
            name="password"
            control={control}
            label="Password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Checkbox control={control} name="roles" options={rolesChecboxOptions} />

          <Select
            name="organizationId"
            control={control}
            label="Organization"
            options={organizationsSelectOptions as SelectOption[]}
          />
          <Button
            disabled={!isDirty || !isValid || isSubmitting}
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
          >
            {submitButtonLabel}
          </Button>
        </>
      )}
    </form>
  )
}

export default UserForm
