import { OperationType, GrantKYCTokenFormData } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, ButtonGroup, FormHelperText } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import { getTraderAddressesForToken, getAllTraders } from '../../../../api/traders'
import { Select } from '../../../../components/ui/Select'

interface GrantKYCTokenProps {
  tokenId: string
  traderId?: string
  traderAddressId?: string
  onSuccess: () => void
}

export interface GrantKYCTokenFormDataWithTrader extends GrantKYCTokenFormData {
  traderId: string
  traderAddressId: string
}

const validationSchema = Yup.object()
  .shape({
    traderId: Yup.string().required('Trader is required'),
    traderAddressId: Yup.string().required('Trader address is required'),
  })
  .required()

function GrantKYCTokenForm({ tokenId, traderId, traderAddressId, onSuccess }: GrantKYCTokenProps) {
  const initialValues = { traderId: traderId || '', traderAddressId: traderAddressId || '' }
  const { mutate } = useMutation(
    (formData: GrantKYCTokenFormDataWithTrader) =>
      createOperation(
        OperationType.GrantKYC,
        { traderAddressId: formData.traderAddressId },
        tokenId,
        formData.traderId,
      ),
    {
      onSuccess: (data) => {
        toast.success('Grant KYC request sent for approval')
      },
      onError: () => {
        toast.error('there was an error')
      },
    },
  )
  const { isLoading: isLoadingTraders, data: tradersData } = useQuery(['get-traders'], () => getAllTraders(), {
    retry: false,
  })

  const onSubmit = async (data: GrantKYCTokenFormDataWithTrader) => {
    try {
      mutate({ traderId: data.traderId, traderAddressId: data.traderAddressId })
      onSuccess()
    } catch (error: any) {}
  }

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { isValid, isSubmitting },
  } = useForm<GrantKYCTokenFormDataWithTrader>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const traderAddressesQuery = useQuery(
    [`get-trader-addressess-for-token`, watch('traderId')],
    () => getTraderAddressesForToken(getValues('traderId'), tokenId),
    {
      retry: false,
      enabled: !!getValues('traderId'),
    },
  )

  useEffect(() => {
    watch((value, { name }) => {
      if (name === 'traderId') {
        setValue('traderAddressId', '')
      }
    })
  }, [watch, setValue])

  const traderAddressOptions = useMemo(() => {
    if (traderAddressesQuery.data) {
      const traderAddressOptions = (traderAddressesQuery.data as any).data.addresses.map((traderAddress: any) => ({
        label: `${traderAddress.name}: ${traderAddress.address}`,
        value: traderAddress.id,
      }))
      return traderAddressOptions
    }
    return []
  }, [traderAddressesQuery.data])

  useEffect(() => {
    if (traderAddressOptions.length === 1) {
      setValue('traderAddressId', traderAddressOptions[0].value)
      trigger('traderAddressId')
    }
  }, [traderAddressOptions, setValue, trigger])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Select
        name="traderId"
        control={control}
        label="Select the trader"
        options={
          isLoadingTraders
            ? []
            : (tradersData as any).data.data.map((trader: any) => ({ label: trader.name, value: trader.id }))
        }
      />
      <Select
        sx={{ mb: 1 }}
        name="traderAddressId"
        control={control}
        label="Select the address"
        disabled={!getValues('traderId') || traderAddressesQuery.isLoading || traderAddressOptions.length === 0}
        options={traderAddressOptions}
      />
      {!traderAddressesQuery.isLoading && getValues('traderId') && traderAddressOptions.length === 0 && (
        <FormHelperText sx={{ color: 'warning.dark' }}>
          There are no addresses for this trader that can be whitelisted.
        </FormHelperText>
      )}
      <Box m={4} />
      <ButtonGroup fullWidth>
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Grant KYC
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default GrantKYCTokenForm
