import { AddPriceFormData } from '@archax/shared-types'
import qs from 'qs'
import { axios } from './axios'
import { PaginationRequestParams } from './types'

export interface GetTokensBaseParams {
  name?: string
  symbol?: string
  protocol?: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}

export interface GetTokensParams extends GetTokensBaseParams, PaginationRequestParams {}

interface GetTokenCapTableParams {
  timestamp: number | null
  id: string
}

export const getToken = (id: string) => {
  return axios.get(`/tokens/${id}`)
}

export interface GetTokenHistoryParams extends PaginationRequestParams {
  id: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}

export interface GetTokenWhitelistParams extends PaginationRequestParams {
  id: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}

export const getTokenHistoryCSVUrl = (params: GetTokenHistoryParams) => {
  const { id, limit, offset, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return `/tokens/${id}/history?${querystring}`
}

export const getTokenHistory = (params: GetTokenHistoryParams) => {
  const { id, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/tokens/${id}/history?${querystring}`)
}

export const getTokenWhitelistCSVUrl = (params: GetTokenWhitelistParams) => {
  const { id, limit, offset, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return `/tokens/${id}/whitelist?${querystring}`
}

export const getTokenWhitelist = (params: GetTokenWhitelistParams) => {
  const { id, sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/tokens/${id}/whitelist?${querystring}`)
}

export const getTokenCapTableCSVUrl = (params: GetTokenCapTableParams) => {
  const { id, timestamp } = params
  return `/tokens/${id}/cap-table${timestamp ? `?timestamp=${timestamp}` : ''}`
}

export const getTokenCapTable = (params: GetTokenCapTableParams) => {
  const { id, timestamp } = params
  return axios.get(`/tokens/${id}/cap-table${timestamp ? `?timestamp=${timestamp}` : ''}`)
}

export const getTokensUrl = (params: GetTokensBaseParams) => {
  const { sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })
  return `/tokens?${querystring}`
}

export const getTokens = (params: GetTokensParams) => {
  const url = getTokensUrl(params)
  return axios.get(url)
}

export const addPrice = (id: string, price: AddPriceFormData) => {
  return axios.post(`/tokens/${id}/prices`, price)
}
