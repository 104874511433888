import { Token } from '@archax/shared-types'
import { Box, Card, CircularProgress, Tabs, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import { Container } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { getToken } from '../../api/tokens'
import TabPanel from '../../components/ui/TabPanel/TabPanel'
import TokenCapTable from './components/TokenCapTable'
import TokenDetails from './components/TokenDetails'
import TokenHistory from './components/TokenHistory'
import TokenWhitelist from './components/TokenWhitelist'

function TokenManagement() {
  const { id } = useParams() as { id: string }
  const { isLoading, data } = useQuery([`get-token-${id}`], () => getToken(id as string), {
    retry: false,
  })
  const [tabId, setTabId] = useState(0)

  if (isLoading) {
    // TODO: Create a Spinner component
    return <CircularProgress />
  }

  if (!data) {
    // TODO: Create a NotFound component
    return <Typography variant="h2">Not found</Typography>
  }

  return (
    <Container maxWidth="xl">
      <Box mb={3}>
        <TokenDetails token={data?.data as Token} />
      </Box>

      <Card sx={{ p: 7, mb: 5 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabId} onChange={(e, newValue) => setTabId(newValue)}>
            <Tab label="History" />
            <Tab label="Whitelist" />
            <Tab label="Cap table" />
          </Tabs>
        </Box>
        <TabPanel value={tabId} index={0}>
          <TokenHistory id={id} />
        </TabPanel>
        <TabPanel value={tabId} index={1}>
          <TokenWhitelist token={data?.data as Token} />
        </TabPanel>
        <TabPanel value={tabId} index={2}>
          <TokenCapTable token={data?.data as Token} />
        </TabPanel>
      </Card>
    </Container>
  )
}

export default TokenManagement
