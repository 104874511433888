import {
  ChainProtocol,
  ExplorerLink,
  OperationProcessStatus,
  OperationStatus,
  OperationType,
  READABLE_OPERATION_TYPE,
  TABLE_HEADER_NAMES,
  TokenHistoryOperation,
  TraderAddress,
} from '@archax/shared-types'
import BlockIcon from '@mui/icons-material/Block'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Link, Switch, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ValueFormatterParams } from 'ag-grid-community'
import { ColDef, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { useCallback, useMemo, useState } from 'react'
import { getTokenHistory, getTokenHistoryCSVUrl, GetTokenHistoryParams } from '../../../api/tokens'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { EXPLORER_LOGOS } from '../../../constants'
import { dateFilterOptions, numberFilterOptions, substringFilterParams } from '../../../util/common-grid-options'

interface TokenHistoryProps {
  id: string
}

const TOKEN_HISTORY_OPERATION_TYPES = [
  OperationType.CreateToken,
  OperationType.Burn,
  OperationType.Mint,
  OperationType.Pause,
  OperationType.Unpause,
  OperationType.GrantKYC,
  OperationType.RevokeKYC,
  OperationType.Wipe,
  OperationType.Send,
]

function TokenHistory({ id }: TokenHistoryProps) {
  const [showFailed, setShowFailed] = useState(false)

  const columnDefs: ColDef<TokenHistoryOperation>[] = useMemo(
    () => [
      {
        field: 'explorer',
        headerName: '',
        cellRenderer: (params: ICellRendererParams<TokenHistoryOperation>) => {
          return (
            <>
              {params.data!.explorerLinks?.map((explorerLink: ExplorerLink, index: number) => (
                <Link key={index} href={explorerLink.url} rel="noreferrer" target="_blank">
                  <img
                    alt={explorerLink.value}
                    src={EXPLORER_LOGOS[params.data!.token!.standard.chain.protocol as ChainProtocol]}
                    width="18"
                  />
                </Link>
              ))}
            </>
          )
        },
        minWidth: 40,
        flex: 1,
        sortable: false,
        filter: false,
      },
      {
        field: 'status',
        cellRenderer: (params: ICellRendererParams<TokenHistoryOperation>) => {
          if (params.data!.status === OperationStatus.NotApproved) {
            return <BlockIcon color="error" />
          }
          if (params.data!.status === OperationStatus.Approved) {
            return params.data!.error ? <CancelIcon color="error" /> : <CheckBoxIcon color="success" />
          }

          return <></>
        },
        headerName: TABLE_HEADER_NAMES.common.operation_status,
        minWidth: 110,
        flex: 1,
        sortable: false,
        filter: false,
        floatingFilter: false,
      },
      {
        field: 'approved_at',
        headerName: TABLE_HEADER_NAMES.common.date_time,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return new Date(params.data!.approvedAt).toLocaleDateString('en-UK', { hour: '2-digit', minute: '2-digit' })
        },
        minWidth: 140,
        flex: 2,
        sortable: true,
        filter: 'agDateColumnFilter',
        filterParams: dateFilterOptions,
      },
      {
        field: 'action_type',
        headerName: TABLE_HEADER_NAMES.common.operation_type,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return READABLE_OPERATION_TYPE[params.data!.type as OperationType]
        },
        minWidth: 160,
        flex: 2,
        sortable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: TOKEN_HISTORY_OPERATION_TYPES,
          valueFormatter: (params: ValueFormatterParams<OperationType>) =>
            READABLE_OPERATION_TYPE[params.value as OperationType],
        },
      },
      {
        field: 'requestor_name',
        headerName: TABLE_HEADER_NAMES.common.requester,
        minWidth: 150,
        flex: 2,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return params.data!.requestor?.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'approver_name',
        headerName: TABLE_HEADER_NAMES.common.approver,
        minWidth: 150,
        flex: 2,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return params.data!.approver?.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'trader_address',
        headerName: TABLE_HEADER_NAMES.common.trader_address,
        valueGetter: (params: ValueGetterParams<any>) => {
          if (params.data!.trader?.addresses) {
            return params.data!.trader.addresses?.address
          }
          if (params.data!.data.to) {
            return params.data!.data.to
          }
          if (params.data!.data.address) {
            return params.data!.data.address
          }
          return ``
        },
        minWidth: 200,
        flex: 4,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'trader_name',
        headerName: TABLE_HEADER_NAMES.common.trader_name,
        valueGetter: (params: ValueGetterParams<TokenHistoryOperation>) => {
          return params.data!.trader?.name
        },
        minWidth: 150,
        flex: 2,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'amount',
        headerName: TABLE_HEADER_NAMES.token_history.amount,
        valueGetter: (params: ValueGetterParams<any>) => {
          return params.data!.data?.amount
        },
        minWidth: 120,
        flex: 2,
        sortable: true,
        filter: 'agNumberColumnFilter',
        filterParams: numberFilterOptions,
      },
    ],
    [],
  )

  const mutateFilters = useCallback(
    (filters: any) => {
      if (!showFailed) {
        filters.operation_status = [OperationProcessStatus.Success]
      }

      return filters
    },
    [showFailed],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      onGridReady({ api }) {
        api.sizeColumnsToFit()
      },
    }),
    [columnDefs],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          History
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Switch
            checked={showFailed}
            onChange={(event) => setShowFailed(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography sx={{ marginRight: 6 }} align="left" variant="body1">
            Show failed actions
          </Typography>
        </Box>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => {
            return getTokenHistory({ ...(gridParams as GetTokenHistoryParams), id })
          }}
          csvExportUrlGetter={(gridParams) => {
            return getTokenHistoryCSVUrl({ ...(gridParams as GetTokenHistoryParams), id })
          }}
          mutateFilters={mutateFilters}
        />
      </Box>
    </div>
  )
}

export default TokenHistory
