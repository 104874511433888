import {
  ChainProtocol,
  CreateTokenOperationData,
  Operation,
  READABLE_PROTOCOL,
  READABLE_STANDARD,
  TokenStandardType,
} from '@archax/shared-types'
import { Button, Card, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { ColDef, GridApi, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { cancelOperation, getMyOperations } from '../../api/operations'
import Dialog from '../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../components/ServerSideGrid'

function EditOperations() {
  const navigate = useNavigate()
  const [selectedRow, setSelectedRow] = useState<Operation | null>(null)
  const [showCancelDialog, setShowCancelDialog] = useState(false)
  const [gridApi, setGridApi] = useState<null | GridApi>(null)

  const { mutate: cancelMutation } = useMutation((id: string) => cancelOperation(id), {
    onSuccess: () => {
      toast.success('Request cancelled.')
      gridApi?.refreshServerSide()
    },
    onError: () => {
      toast.error('there was an error')
    },
  })

  const columnDefs: ColDef<Operation>[] = useMemo(
    () => [
      {
        field: 'data.name',
        headerName: 'Name',
        flex: 1,
        sortable: false,
      },
      {
        field: 'data.protocol',
        headerName: 'Protocol',
        flex: 1,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          return READABLE_PROTOCOL[(params.data!.data as CreateTokenOperationData).protocol as ChainProtocol]
        },
      },
      {
        field: 'data.standard',
        headerName: 'Standard',
        flex: 1,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          return READABLE_STANDARD[(params.data!.data as CreateTokenOperationData).standard as TokenStandardType]
        },
      },

      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<Operation>) => {
          return (
            <Box display="flex">
              <Box marginRight={1}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate(`/tokens/requests/edit/${params.data!.id}`)
                  }}
                >
                  Edit
                </Button>
              </Box>
              <Box marginRight={1}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setSelectedRow(params.data!)
                    setShowCancelDialog(true)
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          )
        },
      },
    ],
    [navigate],
  )

  const handleCancel = async (id: string) => {
    await cancelMutation(id)
    setShowCancelDialog(false)
  }

  // const operationsData = useMemo(() => {
  //   return (
  //     data?.data.data.map((operation: Operation) => {
  //       const row: Partial<Operation> = {
  //         id: operation.id,
  //       }

  //       const operationData = operation.data as CreateTokenOperationData
  //       row.name = operationData.name
  //       row.protocol = READABLE_PROTOCOL[operationData.protocol as ChainProtocol]
  //       row.standard = operationData.standard as TokenStandardType

  //       return row
  //     }) || []
  //   )
  // }, [data])

  const defaultColDef: ColDef = {
    filter: false,
  }

  const gridOptions: GridOptions = {
    columnDefs,
    defaultColDef,
  }

  return (
    <Container maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          Token creation requests
        </Typography>
        <Typography align="left">You can edit the token details before it is approved and created.</Typography>
        <Box>
          <ServerSideGrid gridOptions={gridOptions} queryFn={getMyOperations} setParentGridApi={setGridApi} />
        </Box>
      </Card>
      <Dialog
        title="Are you sure that you want to cancel this request?"
        onConfirm={() => handleCancel(selectedRow?.id!)}
        confirmLabel="Cancel request"
        cancelLabel="Close"
        onClose={() => setShowCancelDialog(false)}
        open={showCancelDialog}
        showCancel
      >
        <Typography variant="body2">You can not revert this action.</Typography>
      </Dialog>
    </Container>
  )
}

export default EditOperations
