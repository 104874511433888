import { LoginResponse } from '@archax/shared-types'
import axios, { AxiosResponse } from 'axios'
import { LoginFormData, RegisterFormData } from './types'

const API_URL = process.env.REACT_APP_API_URL

const authService = axios.create({
  baseURL: API_URL,
})

function onResponse(response: AxiosResponse) {
  if (response.data) {
    return response.data
  }
  return response
}

function onResponseError(error: any) {
  if (error.response) {
    const data = error.response.data
    const status = error.response.status
    return Promise.reject({ status, data })
  }
  return Promise.reject(error)
}

authService.interceptors.response.use(onResponse, onResponseError)

async function login(credentials: LoginFormData): Promise<LoginResponse> {
  const result = await authService.post<never, LoginResponse>('/login', credentials)
  return result
}

async function register(registration: RegisterFormData) {
  const result = await authService.post('/users', registration)
  return result
}

export { login, register }
