import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import { Stack } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { User, UserRole } from '@archax/shared-types'
import ArchaxLogo from '../../assets/logo.svg'
import { useGlobalContext } from '../../context'
import { hasOneOfRoles } from '../../util/user-roles'

interface Page {
  title: string
  url: string
  visibleFor?: UserRole[]
  customValidator?: (page: Page, user: User) => boolean
}

const menu: Page[] = [
  { title: 'Create token', url: '/tokens/create', visibleFor: [UserRole.User] },
  { title: 'Token management', url: '/tokens' },
  { title: 'Admin approval', url: '/tokens/requests', visibleFor: [UserRole.Approver] },
  { title: 'Edit requests', url: '/tokens/requests/edit', visibleFor: [UserRole.User] },
  { title: 'Traders', url: '/traders' },
  { title: 'Users', url: '/users', visibleFor: [UserRole.Admin] },
  {
    title: 'Organizations',
    url: '/organizations',
    visibleFor: [UserRole.Admin],
    customValidator: (page: Page, user: User) => {
      return user.organization.isAdmin
    },
  },
  {
    title: 'Organization',
    url: '/organization',
    visibleFor: [UserRole.Admin],
    customValidator: (page: Page, user: User) => {
      return !user.organization.isAdmin
    },
  },
]

const settings = ['Profile']

const pageIsVisible = (page: Page, user: User) => {
  if (!page.visibleFor) {
    return true
  }
  if (page.customValidator) {
    return page.customValidator(page, user) && hasOneOfRoles(page.visibleFor, user)
  }
  return hasOneOfRoles(page.visibleFor, user)
}

const isBackgroundTransparent = (user: User) => user.logoHasTransparentBackground

function Header() {
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
  const {
    state: { user },
  } = useGlobalContext()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const {
    actions: {
      auth: { logout },
    },
  } = useGlobalContext()

  const getLogoSrc = (user: User) => {
    if (user.logoId) return `/api/organizations/${user.organizationId}/logo/${user.logoId}`
    return ArchaxLogo
  }
  const handleLogout = () => {
    logout()
    navigate('/login')
  }
  if (!user) {
    return <></>
  }
  const userMenu = menu.filter((page) => pageIsVisible(page, user))

  return (
    <AppBar color="transparent" position="static" elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            width="64px"
            height="64px"
            alt="Archax logo"
            src={getLogoSrc(user)}
            onError={(e) => {
              ;(e.target as HTMLImageElement).src = ArchaxLogo
            }}
            style={{
              backgroundColor: isBackgroundTransparent(user) ? 'transparent' : 'white',
              padding: '7px',
              borderRadius: '6px',
              objectFit: 'cover',
            }}
          />
          <Box sx={{ alignContents: 'center', flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: 'background.paper' }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {userMenu.map((page) => (
                <MenuItem
                  key={page.url}
                  onClick={() => {
                    handleCloseNavMenu()
                    navigate(page.url)
                  }}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {userMenu.map((page) => (
              <Button
                key={page.url}
                onClick={() => {
                  handleCloseNavMenu()
                  navigate(page.url)
                }}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ border: 'solid', borderColor: 'white', color: 'white' }}>
                <PersonIcon sx={{ fontSize: 14 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            sx={{
              border: 'solid',
              borderColor: 'white',
              color: 'white',
              borderRadius: 25,
              marginLeft: 2,
              marginRight: 3,
              paddingLeft: 3,
              paddingRight: 3,
              cursor: 'pointer',
            }}
            onClick={() => handleLogout()}
          >
            <Typography fontSize={14}>Log out</Typography>
            <LogoutIcon />
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
