import {
  NFT_STANDARDS,
  OperationType,
  READABLE_KYC_STATUS,
  RevokeKYCTokenFormData,
  TABLE_HEADER_NAMES,
  Token,
  TokenTraderAddress,
  TokenWhitelistData,
  TraderAddress,
  TraderOnChainData,
} from '@archax/shared-types'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ColDef, GridOptions, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { createOperation } from '../../../api/operations'
import { getTokenWhitelist, getTokenWhitelistCSVUrl, GetTokenWhitelistParams } from '../../../api/tokens'
import Dialog from '../../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../../components/ServerSideGrid'
import { AddButton } from '../../../components/ui/AddButton'
import { useGlobalContext } from '../../../context'
import { hasUserRole } from '../../../util/user-roles'
import { GrantKYCTokenForm } from './GrantKYCTokenForm'
import { MintToTokenForm } from './MintToTokenForm'
import { SendNFTTokenForm } from './SendNFTTokenForm'
import { SendTokenForm } from './SendTokenForm'
import { WipeNFTTokenForm } from './WipeNFTTokenForm'
import { WipeTokenForm } from './WipeTokenForm'
import { substringFilterParams } from '../../../util/common-grid-options'
interface TokenWhitelistProps {
  token: Token
}

interface RevokeKYCOperationData {
  formData: RevokeKYCTokenFormData
  traderId: string
}

function TokenWhitelist({ token }: TokenWhitelistProps) {
  const { id, standard } = token
  const [showSendTokenDialog, setShowSendTokenDialog] = useState(false)
  const [showMintToTokenDialog, setShowMintToTokenDialog] = useState(false)
  const [showWipeTokenDialog, setShowWipeTokenDialog] = useState(false)
  const [showGrantKYCTokenDialog, setShowGrantKYCTokenDialog] = useState(false)
  const [showRevokeKYCTokenDialog, setShowRevokeKYCTokenDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<TraderAddress | null>(null)
  const {
    state: { user },
  } = useGlobalContext()

  const { mutate: revokeKYCMutation } = useMutation(
    (data: RevokeKYCOperationData) => createOperation(OperationType.RevokeKYC, data.formData, id, data.traderId),
    {
      onSuccess: () => {
        toast.success('Revoke KYC request sent for approval')
      },
      onError: () => {
        toast.error('there was an error')
      },
    },
  )

  const handleRevokeKYC = async (row: TraderAddress) => {
    revokeKYCMutation({ formData: { traderAddressId: row.id }, traderId: row.trader.id })
    setShowRevokeKYCTokenDialog(false)
  }

  const isNft = NFT_STANDARDS.includes(standard.name)
  const canMint = !isNft || (isNft && token.onChainData?.totalSupply === 0)
  const canSend = !isNft || (isNft && token.onChainData?.balance! > 0)

  const columnDefs: ColDef<TokenWhitelistData>[] = useMemo(
    () => [
      {
        field: 'status',
        headerName: TABLE_HEADER_NAMES.token_whitelist.is_whitelisted,
        // flex: 1,
        minWidth: 130,
        valueGetter: (params: ValueGetterParams<TokenWhitelistData>) => {
          return params.data!.isWhitelisted ? READABLE_KYC_STATUS.ENABLED : READABLE_KYC_STATUS.DISABLED
        },
        sortable: false,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(READABLE_KYC_STATUS),
        },
      },
      {
        field: 'trader_name',
        headerName: TABLE_HEADER_NAMES.common.trader_name,
        flex: 1,
        minWidth: 160,
        valueGetter: (params: ValueGetterParams<TokenWhitelistData>) => {
          return params.data!.traderAddress.trader.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'onChainData.balance',
        headerName: TABLE_HEADER_NAMES.common.balance,
        flex: 1,
        minWidth: 130,
        sortable: false,
        filter: false,
        cellRenderer: (params: ICellRendererParams<TokenWhitelistData>) => {
          if (params.data!.onChainData.balance === undefined || params.data!.onChainData.balance === null) {
            return (
              <Tooltip title="Could not retrieve the balance">
                <Typography>
                  <WarningIcon />
                </Typography>
              </Tooltip>
            )
          }

          return params.data!.onChainData.balance
        },
      },
      {
        field: 'address_name',
        headerName: TABLE_HEADER_NAMES.common.address_name,
        flex: 1,
        minWidth: 160,
        valueGetter: (params: ValueGetterParams<TokenWhitelistData>) => {
          return params.data!.traderAddress.name
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'trader_address',
        headerName: TABLE_HEADER_NAMES.common.address,
        flex: 3,
        minWidth: 220,
        valueGetter: (params: ValueGetterParams<TokenWhitelistData>) => {
          return params.data!.traderAddress.address
        },
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
    ],
    [],
  )

  const userRoleColumnDefs: ColDef<TokenWhitelistData>[] = useMemo(
    () => [
      {
        field: 'actions',
        headerName: '',
        flex: 1,
        minWidth: 400,
        sortable: false,
        filter: false,
        cellRenderer: (params: ICellRendererParams<TokenWhitelistData>) => {
          console.log("🚀 ~ file: TokenWhitelist.tsx:160 ~ TokenWhitelist ~ params:", params)
          const isWhitelisted = params.data!.isWhitelisted
          return (
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                {isWhitelisted && (
                  <>
                    <Box marginRight={1}>
                      <Button
                        disabled={!canSend}
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setSelectedRow(params.data!.traderAddress)
                          setShowSendTokenDialog(true)
                        }}
                      >
                        Send
                      </Button>
                    </Box>
                    <Box marginRight={1}>
                      <Button
                        disabled={!canMint}
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setSelectedRow(params.data!.traderAddress)
                          setShowMintToTokenDialog(true)
                        }}
                      >
                        Mint
                      </Button>
                    </Box>
                    <Box marginRight={1}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          setSelectedRow(params.data!.traderAddress)
                          setShowWipeTokenDialog(true)
                        }}
                      >
                        Wipe
                      </Button>
                    </Box>
                  </>
                )}
                <Box marginRight={1}>
                  <Button
                    sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setSelectedRow(params.data!.traderAddress)
                      isWhitelisted ? setShowRevokeKYCTokenDialog(true) : setShowGrantKYCTokenDialog(true)
                    }}
                  >
                    {isWhitelisted ? 'Revoke KYC' : 'Grant KYC'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          )
        },
      },
    ],
    [canMint, canSend],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs: hasUserRole(user) ? [...columnDefs, ...userRoleColumnDefs] : columnDefs,
    }),
    [columnDefs, userRoleColumnDefs, user],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          Whitelist
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          {hasUserRole(user) && (
            <AddButton
              name="whitelist-addition"
              ariaLabel="Grant KYC"
              onClick={() => {
                setSelectedRow(null)
                setShowGrantKYCTokenDialog(true)
              }}
            ></AddButton>
          )}
        </Box>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => {
            return getTokenWhitelist({ ...(gridParams as GetTokenWhitelistParams), id })
          }}
          csvExportUrlGetter={(gridParams) => {
            return getTokenWhitelistCSVUrl({
              ...(gridParams as GetTokenWhitelistParams),
              id,
            })
          }}
        />
      </Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        title={`Grant KYC`}
        onClose={() => setShowGrantKYCTokenDialog(false)}
        open={showGrantKYCTokenDialog}
      >
        <GrantKYCTokenForm
          traderId={selectedRow?.trader.id}
          traderAddressId={selectedRow?.id}
          onSuccess={() => setShowGrantKYCTokenDialog(false)}
          tokenId={id}
        />
      </Dialog>
      <Dialog
        title={`Send tokens to: ${selectedRow?.name}(${selectedRow?.name})`}
        onClose={() => setShowSendTokenDialog(false)}
        open={showSendTokenDialog}
      >
        {isNft && (
          <SendNFTTokenForm
            onSuccess={() => setShowSendTokenDialog(false)}
            tokenId={id}
            account={selectedRow!}
            assets={token.onChainData?.assets!}
          />
        )}
        {!isNft && (
          <SendTokenForm onSuccess={() => setShowSendTokenDialog(false)} tokenId={id} account={selectedRow!} />
        )}
      </Dialog>
      <Dialog
        title={`${isNft ? 'Mint token to' : 'Mint tokens to'}: ${selectedRow?.name}(${selectedRow?.name})`}
        onClose={() => setShowMintToTokenDialog(false)}
        open={showMintToTokenDialog}
      >
        <MintToTokenForm
          onSuccess={() => setShowMintToTokenDialog(false)}
          tokenId={id}
          account={selectedRow!}
          isNft={isNft}
        />
      </Dialog>
      <Dialog
        title={`Wipe tokens from: ${selectedRow?.name}(${selectedRow?.name})`}
        onClose={() => setShowWipeTokenDialog(false)}
        open={showWipeTokenDialog}
      >
        {isNft && (
          <WipeNFTTokenForm onSuccess={() => setShowWipeTokenDialog(false)} tokenId={id} account={selectedRow!} />
        )}
        {!isNft && (
          <WipeTokenForm onSuccess={() => setShowWipeTokenDialog(false)} tokenId={id} account={selectedRow!} />
        )}
      </Dialog>
      <Dialog
        title="Are you sure that you want to revoke account access to the token?"
        onConfirm={() => handleRevokeKYC(selectedRow!)}
        confirmLabel="Revoke KYC"
        onClose={() => setShowRevokeKYCTokenDialog(false)}
        open={showRevokeKYCTokenDialog}
        showCancel
      >
        <Typography variant="body2">You can grant the KYC back later.</Typography>
      </Dialog>
    </div>
  )
}

export default TokenWhitelist
