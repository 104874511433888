import Button from '@mui/material/Button'
import { useForm } from 'react-hook-form'
import { LoginFormData } from '../../types'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Typography } from '@mui/material'
import TextField from '../../../../components/ui/TextField/TextField'

const validationSchema = Yup.object()
  .shape({
    email: Yup.string().email().required(),
    password: Yup.string().required().min(6).max(100),
  })
  .required()

const initialValues = { email: '', password: '' }

interface LoginFormProps {
  onSubmit: (data: LoginFormData) => void
}
function LoginForm({ onSubmit }: LoginFormProps) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginFormData>({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onBlur',
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography align="center" variant="h2">
        Login
      </Typography>
      <TextField name="email" control={control} label="Email" />
      <TextField name="password" control={control} label="Password" type="password" />

      <div className="button-container">
        <Button disabled={isSubmitting} type="submit" variant="contained" size="large" color="primary" fullWidth>
          <Typography>Login</Typography>
        </Button>
      </div>
    </form>
  )
}
export default LoginForm
