import { Avatar, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { MouseEvent } from 'react'

interface AddButtonProps {
  name: string
  ariaLabel: string
  onClick: (_: MouseEvent) => void
}

const AddButton: React.FunctionComponent<AddButtonProps> = ({ name, ariaLabel, onClick }) => {
  return (
    <IconButton aria-label={ariaLabel} onClick={onClick} sx={{ '&:hover': { backgroundColor: 'info.light' } }}>
      <Avatar sx={{ backgroundColor: 'primary.main', width: 24, height: 24 }}>
        <AddIcon sx={{ color: 'info.light' }} />
      </Avatar>
    </IconButton>
  )
}

export default AddButton
