import { UserFormData } from '@archax/shared-types'
import { Card, Container, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createUser } from '../../api/users'
import UserForm from './components/UserForm'

function CreateUsers() {
  const navigate = useNavigate()
  const { mutate } = useMutation((formData: UserFormData) => createUser(formData), {
    onSuccess: (data) => {
      toast.success('User created successfully')
      navigate('/users')
    },
    onError: () => {
      toast.error('There was an error')
    },
  })

  const onSubmit = async (data: UserFormData) => {
    try {
      mutate(data)
    } catch (error: any) {}
  }

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          Create user
        </Typography>
        <UserForm mode="create" onSubmit={onSubmit} />
      </Card>
    </Container>
  )
}

export default CreateUsers
