import { MintToTokenFormData, OperationType, TraderAddress } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'
import TextField from '../../../../components/ui/TextField/TextField'

interface MintToTokenProps {
  tokenId: string
  isNft: boolean
  onSuccess: () => void
  account: TraderAddress
}

interface MintToTokenFormDataWithAddress extends MintToTokenFormData {
  to: string
  traderAddressId: string
}

const validationSchema = Yup.object()
  .shape({
    amount: Yup.number()
      .positive()
      .integer('Amount must be a positive number.')
      .min(0, 'Amount must be a positive number.')
      .typeError('Amount must be a positive number.'),
  })
  .required()

function MintToTokenForm({ tokenId, onSuccess, account, isNft }: MintToTokenProps) {
  const initialValues = { amount: isNft ? 1 : 0 }
  const { mutate } = useMutation(
    (formData: MintToTokenFormDataWithAddress) =>
      createOperation(OperationType.Mint, formData, tokenId, account.trader.id),
    {
      onSuccess: (data) => {
        toast.success('Mint token request sent for approval')
      },
      onError: () => {
        toast.error('there was an error')
      },
    },
  )

  const onSubmit = async (data: MintToTokenFormData) => {
    try {
      await mutate({ to: account.address, traderAddressId: account.id, ...data })
      onSuccess()
    } catch (error: any) {}
  }

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<MintToTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!isNft && <TextField name="amount" type="number" control={control} label="Number to mint" />}
      <ButtonGroup fullWidth>
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Mint
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default MintToTokenForm
