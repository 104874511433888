import { AccountId } from '@hashgraph/sdk'

export function isValidHederaAddress(address: string): boolean {
  try {
    AccountId.fromString(address)
    return true
  } catch (error) {
    return false
  }
}
