import { User } from '@archax/shared-types'
import { Card, Container, Typography } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { editOrganization, getOrganizationById, OrganizationCreateParams } from '../../api/organizations'
import { useGlobalContext } from '../../context'
import OrganizationsForm from './components/OrganizationsForm'

interface EditOrganizationProps {
  organizationId?: string
}

function EditOrganization({ organizationId }: EditOrganizationProps) {
  const {
    state: { user },
    actions: {
      auth: { getUser },
    },
  } = useGlobalContext()

  const { mutate } = useMutation(
    (formData: OrganizationCreateParams) => editOrganization(organizationId || (user as User).organizationId, formData),
    {
      onSuccess: (data) => {
        toast.success('Organization edited successfully')
        getUser()
      },
      onError: () => {
        toast.error('There was an error')
      },
    },
  )

  const { isLoading, data, isFetching } = useQuery(
    ['get-organization'],
    () => getOrganizationById(organizationId || (user as User).organizationId),
    {
      refetchOnWindowFocus: false,
    },
  )
  const onSubmit = async (data: OrganizationCreateParams) => {
    try {
      mutate(data)
    } catch (error: any) {}
  }

  return (
    <Container>
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          Edit Organization
        </Typography>
        {isLoading && <div>Loading...</div>}
        {!isFetching && data && (
          <OrganizationsForm
            onSubmit={onSubmit}
            mode="edit"
            defaultValues={{
              name: data.data.name,
              createdAt: data.data.createdAt,
              logo: data.data.logoId,
              logoHasTransparentBackground: data.data.logoHasTransparentBackground,
            }}
            organizationId={organizationId || (user as User).organizationId}
          />
        )}
      </Card>
    </Container>
  )
}

export default EditOrganization
