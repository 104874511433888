import { TraderCreationFormData, TRADER_NAME_MIN_LENGTH, TRADER_NAME_MAX_LENGTH } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createTrader } from '../../../api/traders'
import TextField from '../../../components/ui/TextField/TextField'

interface CreateTraderProps {
  traderNames: string[]
  onSuccess: () => void
}

function CreateTraderForm({ traderNames, onSuccess }: CreateTraderProps) {
  const validationSchema = Yup.object()
    .shape({
      name: Yup.string()
        .min(TRADER_NAME_MIN_LENGTH, `Minimum length is ${TRADER_NAME_MIN_LENGTH}`)
        .max(TRADER_NAME_MAX_LENGTH)
        .notOneOf(traderNames, 'This trader name is already used')
        .required(),
    })
    .required()

  const initialValues = { name: '' }

  const { mutate } = useMutation((formData: TraderCreationFormData) => createTrader(formData.name), {
    onSuccess: (data) => {
      toast.success('Trader created')
      onSuccess()
    },
    onError: () => {
      toast.error('there was an error')
    },
  })

  const onSubmit = async (data: TraderCreationFormData) => {
    try {
      mutate(data)
    } catch (error: any) {}
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<TraderCreationFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="name"
        control={control}
        label="Name"
        inputProps={{ minLength: TRADER_NAME_MIN_LENGTH, maxLength: TRADER_NAME_MAX_LENGTH }}
      />

      <ButtonGroup fullWidth>
        <Button
          disabled={!isDirty || !isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Create
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default CreateTraderForm
