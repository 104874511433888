import {
  ChainProtocol,
  READABLE_PROTOCOL,
  READABLE_STANDARD,
  TABLE_HEADER_NAMES,
  Token,
  TokenStandardType,
} from '@archax/shared-types'
import { Card, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import { ColDef, GridOptions, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { getTokens, getTokensUrl } from '../../api/tokens'
import { ServerSideGrid } from '../../components/ServerSideGrid'
import { substringFilterParams } from '../../util/common-grid-options'

function ListTokens() {
  const navigate = useNavigate()
  const columnDefs: ColDef<Token>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: TABLE_HEADER_NAMES.common.name,
        width: 300,
        sortable: true,
        sort: 'asc',
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'symbol',
        headerName: TABLE_HEADER_NAMES.common.symbol,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: substringFilterParams,
      },
      {
        field: 'protocol',
        headerName: TABLE_HEADER_NAMES.common.protocol,
        valueGetter: (params) => READABLE_PROTOCOL[params.data!.standard.chain.protocol as ChainProtocol],
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: Object.values(ChainProtocol),
          valueFormatter: (params: ValueFormatterParams<Token>) => READABLE_PROTOCOL[params.value as ChainProtocol],
        },
      },
      {
        field: 'standard',
        headerName: TABLE_HEADER_NAMES.common.standard,
        valueGetter: (params) => READABLE_STANDARD[params.data!.standard.name as TokenStandardType],
        sortable: true,
        filter: true,
        filterParams: {
          values: Object.values(TokenStandardType),
          valueFormatter: (params: ValueFormatterParams<Token>) => READABLE_STANDARD[params.value as TokenStandardType],
        },
      },
    ],
    [],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      floatingFilter: true,
      onRowClicked: (params: RowClickedEvent<Token>) => {
        navigate(`/tokens/${params.data!.id}`)
      },
    }),
    [navigate, columnDefs],
  )

  return (
    <Container maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          Token management
        </Typography>
        <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
          <Typography align="left">Select the token you would like to manage</Typography>
        </Box>
        <Box>
          <ServerSideGrid gridOptions={gridOptions} queryFn={getTokens} csvExportUrlGetter={getTokensUrl} />
        </Box>
      </Card>
    </Container>
  )
}

export default ListTokens
