import { ChainProtocol, TokenStandardType } from '@archax/shared-types'
import AlgoExplorerLogo from './assets/algoexplorer-logo.svg'
import EtherscanLogo from './assets/etherscan-logo-circle.svg'
import HederaExplorerLogo from './assets/hederaexplorer-logo.png'
import PolyscanLogo from './assets/polyscan-logo.svg'

export const EXPLORER_LOGOS = {
  [ChainProtocol.Algorand]: AlgoExplorerLogo,
  [ChainProtocol.Ethereum]: EtherscanLogo,
  [ChainProtocol.Hedera]: HederaExplorerLogo,
  [ChainProtocol.Polygon]: PolyscanLogo,
}

export const STANDARDS_WITH_PREMINT = [TokenStandardType.ASA, TokenStandardType.ERC20, TokenStandardType.FungibleCommon]

export const DEFAULT_PAGE_SIZE = 10
