import { OperationType, TokenFormData } from '@archax/shared-types'
import { Card, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { createOperation } from '../../api/operations'
import TokenForm from './components/TokenForm/TokenForm'

function CreateToken() {
  const { mutateAsync } = useMutation(
    (formData: TokenFormData) => createOperation(OperationType.CreateToken, formData),
    {
      onSuccess: () => {
        toast.success('Token creation request sent for approval')
      },
      onError: () => {
        toast.error('there was an error')
      },
    },
  )

  const onSubmit = async (data: TokenFormData) => {
    await mutateAsync(data)
  }

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7 }}>
        <Typography align="center" variant="h2">
          Request token creation
        </Typography>
        <TokenForm mode="create" onSubmit={onSubmit} />
      </Card>
    </Container>
  )
}
export default CreateToken
