import { APIErrorResponse, Trader } from '@archax/shared-types'
import { Button, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { BaseSyntheticEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { archiveTrader, reactivateTrader } from '../../../../api/traders'
import Dialog from '../../../../components/Dialog/Dialog'

interface TraderStatusProps {
  trader: Trader
  size?: 'small' | 'medium' | 'large'
  refetch: () => void
}

function TraderStatusChangeButton({ trader, size = 'small', refetch, ...props }: TraderStatusProps) {
  const [showArchiveTraderDialog, setShowArchiveTraderDialog] = useState(false)
  const [showReactivateTraderDialog, setShowReactivateTraderDialog] = useState(false)
  const { deletedAt } = trader

  const { mutate: archiveTraderMutation } = useMutation((id: string) => archiveTrader(id), {
    onSuccess: () => {
      refetch()
      toast.success('Trader archived')
    },
    onError: (error: AxiosError<APIErrorResponse>) => {
      const errorMessage = error.response?.data.error.message || 'There was an error'
      toast.error(errorMessage)
    },
  })

  const { mutate: reactivateTraderMutation } = useMutation((id: string) => reactivateTrader(id), {
    onSuccess: () => {
      refetch()
      toast.success('Trader reactivated')
    },
    onError: () => {
      toast.error('There was an error')
    },
  })

  function handleArchiveTrader(trader: Trader) {
    archiveTraderMutation(trader.id)
    setShowArchiveTraderDialog(false)
  }

  const openArchiveDialog = () => {
    setShowArchiveTraderDialog(true)
  }

  const reactivateAction = (trader: Trader) => {
    reactivateTraderMutation(trader.id)
    setShowReactivateTraderDialog(false)
  }

  const openReactivateDialog = () => {
    setShowReactivateTraderDialog(true)
  }

  const statusText = !deletedAt ? 'Archive' : 'Reactivate'
  const statusColor = !deletedAt ? 'primary' : 'alternative'
  const action = !deletedAt ? openArchiveDialog : openReactivateDialog

  return (
    <>
      <Button
        variant="contained"
        color={statusColor}
        size={size}
        sx={{
          border: deletedAt && '1px solid #13103E',
        }}
        onClick={(event: BaseSyntheticEvent) => {
          action()
          event.stopPropagation()
        }}
      >
        <Typography variant="button">{statusText}</Typography>
      </Button>

      <Dialog
        title="Are you sure you want to archive the trader?"
        onConfirm={() => handleArchiveTrader(trader!)}
        confirmLabel="Archive"
        onClose={() => setShowArchiveTraderDialog(false)}
        open={showArchiveTraderDialog}
        showCancel
      >
        {' '}
      </Dialog>

      <Dialog
        title="Are you sure you want to reactivate the trader?"
        onConfirm={() => reactivateAction(trader!)}
        confirmLabel="Reactivate"
        onClose={() => setShowReactivateTraderDialog(false)}
        open={showReactivateTraderDialog}
        showCancel
      >
        {' '}
      </Dialog>
    </>
  )
}

export default TraderStatusChangeButton
