import { AddPriceFormData, Currency, PriceType, READABLE_DATE_FORMAT, READABLE_PRICE_TYPE } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useMutation } from '@tanstack/react-query'
import dayjs, { Dayjs } from 'dayjs'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { addPrice } from '../../../../api/tokens'
import CurrencyField from '../../../../components/ui/CurrencyField/CurrencyField'
import Select from '../../../../components/ui/Select/Select'
import TextField from '../../../../components/ui/TextField/TextField'

const initialValues = { price: '', type: '', notes: '', effectiveDate: '' }

interface AddPriceFormProps {
  tokenId: string
  minEffectiveDateTime: string
  currency: Currency
  onSuccess: () => void
  onClose: () => void
}

function AddPriceForm({ tokenId, minEffectiveDateTime, onSuccess, onClose, currency }: AddPriceFormProps) {
  const validationSchema = Yup.object()
    .shape({
      price: Yup.string().required('Price required'),
      type: Yup.string().oneOf(Object.values(PriceType)).required(),
      notes: Yup.string().optional(),
      effectiveDate: Yup.string()
        .test('is-after', 'The date must be later than the token creation date', (value) =>
          dayjs(minEffectiveDateTime).isBefore(dayjs(value)),
        )
        .required(),
    })
    .required()

  const { mutate } = useMutation((formData: AddPriceFormData) => addPrice(tokenId, formData), {
    onSuccess,
    onError: () => {
      toast.error('there was an error')
    },
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting, errors },
    reset,
    setValue,
    getValues,
  } = useForm<AddPriceFormData>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  const effectiveDate = getValues('effectiveDate')
  const effectiveDateErrorMessage = errors.effectiveDate?.message

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        await mutate(data)
        toast.success('Price added')
        reset()
      })}
    >
      <CurrencyField
        name="price"
        control={control}
        label="Price"
        currency={currency}
        setValue={setValue}
      />
      <DateTimePicker
        format={READABLE_DATE_FORMAT}
        value={effectiveDate ? dayjs(effectiveDate) : null}
        label="Effective date"
        minDateTime={dayjs(minEffectiveDateTime)}
        onChange={(newDate) => {
          if (!newDate || !newDate?.isValid()) {
            return
          }
          setValue('effectiveDate', (newDate as Dayjs).toISOString(), { shouldValidate: true })
        }}
        slotProps={{
          textField: {
            variant: 'standard',
            fullWidth: true,
            error: Boolean(effectiveDateErrorMessage),
            helperText: effectiveDateErrorMessage,
          },
        }}
        sx={{ marginBottom: 4 }}
      />
      <Select
        name="type"
        control={control}
        label="Type"
        options={[
          { label: READABLE_PRICE_TYPE[PriceType.Estimate], value: PriceType.Estimate },
          { label: READABLE_PRICE_TYPE[PriceType.Final], value: PriceType.Final },
        ]}
      />
      <TextField name="notes" control={control} label="Notes (optional)" />
      <ButtonGroup fullWidth>
        <Button size="large" fullWidth onClick={onClose}>
          Cancel
        </Button>

        <Button
          disabled={!isDirty || !isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Add price
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default AddPriceForm
