"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationErrorMessage = void 0;
var OrganizationErrorMessage;
(function (OrganizationErrorMessage) {
    OrganizationErrorMessage["CreationForbidden"] = "You cannot create a new organization";
    OrganizationErrorMessage["ModificationForbidden"] = "You can only modify own organization";
    OrganizationErrorMessage["LogoNotFound"] = "Organization logo not found";
    OrganizationErrorMessage["OrganizationNotFound"] = "Organization not found";
})(OrganizationErrorMessage = exports.OrganizationErrorMessage || (exports.OrganizationErrorMessage = {}));
