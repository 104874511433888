import { OperationType, TraderAddress, WipeNFTTokenFormData } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'

interface WipeNFTTokenProps {
  tokenId: string
  onSuccess: () => void
  account: TraderAddress
}

interface WipeNFTTokenFormDataWithAddress extends WipeNFTTokenFormData {
  address: string
  traderAddressId: string
}

const validationSchema = Yup.object()
  .shape({
    tokenId: Yup.number()
      .positive()
      .integer('NFT id must be a positive number.')
      .min(0, 'NFT id must be a positive number.')
      .typeError('NFT id must be a positive number.'),
  })
  .required()

function WipeNFTTokenForm({ tokenId, onSuccess, account }: WipeNFTTokenProps) {
  // Theu user shouldn't be here on this form if the holder doesn't has NFTs
  // But we still try with id 0 just in case...
  const initialValues = { tokenId: account.onChainData.assets?.[0].tokenId || 0 }
  const { mutate } = useMutation(
    (formData: WipeNFTTokenFormDataWithAddress) => createOperation(OperationType.Wipe, formData, tokenId),
    {
      onSuccess: (data) => {
        toast.success('Wipe token request sent for approval')
      },
      onError: () => {
        toast.error('there was an error')
      },
    },
  )

  const onSubmit = async (data: WipeNFTTokenFormData) => {
    try {
      await mutate({ address: account.address, traderAddressId: account.id, ...data })
      onSuccess()
    } catch (error: any) {}
  }

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<WipeNFTTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Later on if we move to collections we need add a NFT id selector here
          The ids for the selector should come from: account.assets */}

      <ButtonGroup fullWidth>
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Wipe
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default WipeNFTTokenForm
