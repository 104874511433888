"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVM_CHAIN_PROTOCOLS = exports.ChainProtocol = void 0;
var ChainProtocol;
(function (ChainProtocol) {
    ChainProtocol["Algorand"] = "ALGORAND";
    ChainProtocol["Ethereum"] = "ETHEREUM";
    ChainProtocol["Hedera"] = "HEDERA";
    ChainProtocol["Polygon"] = "POLYGON";
})(ChainProtocol = exports.ChainProtocol || (exports.ChainProtocol = {}));
exports.EVM_CHAIN_PROTOCOLS = [ChainProtocol.Ethereum, ChainProtocol.Polygon];
