"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Currency = exports.PriceType = exports.TokenPatchOperationsStrings = exports.TokenPatchOperations = exports.TokenStatus = void 0;
__exportStar(require("./chain"), exports);
__exportStar(require("./errors"), exports);
__exportStar(require("./standard"), exports);
__exportStar(require("./operation"), exports);
__exportStar(require("./"), exports);
var TokenStatus;
(function (TokenStatus) {
    TokenStatus["Deploying"] = "DEPLOYING";
    TokenStatus["OnChain"] = "ON_CHAIN";
    TokenStatus["Cancelled"] = "CANCELLED";
})(TokenStatus = exports.TokenStatus || (exports.TokenStatus = {}));
var TokenPatchOperations;
(function (TokenPatchOperations) {
    TokenPatchOperations["Approve"] = "APPROVE";
    TokenPatchOperations["NotApproved"] = "NOT_APPROVED";
    TokenPatchOperations["Cancel"] = "CANCEL";
    TokenPatchOperations["Mint"] = "MINT";
    TokenPatchOperations["Edit"] = "EDIT";
})(TokenPatchOperations = exports.TokenPatchOperations || (exports.TokenPatchOperations = {}));
exports.TokenPatchOperationsStrings = Object.values(TokenPatchOperations);
var PriceType;
(function (PriceType) {
    PriceType["Estimate"] = "ESTIMATE";
    PriceType["Final"] = "FINAL";
})(PriceType = exports.PriceType || (exports.PriceType = {}));
var Currency;
(function (Currency) {
    Currency["BritishPound"] = "GBP";
    Currency["USDollar"] = "USD";
})(Currency = exports.Currency || (exports.Currency = {}));
