import { NFTInfo, OperationType, SendNFTTokenFormData, TraderAddress } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'

interface SendNFTTokenProps {
  tokenId: string
  onSuccess: () => void
  account: TraderAddress
  assets: NFTInfo[]
}

export interface SendNFTTokenFormDataWithAddress extends SendNFTTokenFormData {
  to: string
  traderAddressId: string
}

const validationSchema = Yup.object()
  .shape({
    tokenId: Yup.number()
      .positive()
      .integer('NFT id must be a positive number.')
      .min(0, 'NFT id must be a positive number.')
      .typeError('NFT id must be a positive number.'),
  })
  .required()

function SendNFTTokenForm({ tokenId, onSuccess, account, assets }: SendNFTTokenProps) {
  const initialValues = { tokenId: assets?.[0].tokenId || 0 }
  const { mutate } = useMutation(
    (formData: SendNFTTokenFormDataWithAddress) => createOperation(OperationType.Send, formData, tokenId),
    {
      onSuccess: (data) => {
        toast.success('Send token request sent for approval')
      },
      onError: () => {
        toast.error('there was an error')
      },
    },
  )

  const onSubmit = async (data: SendNFTTokenFormData) => {
    try {
      await mutate({ to: account.address, traderAddressId: account.id, ...data })
      onSuccess()
    } catch (error: any) {}
  }

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<SendNFTTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Later on if we move to collections we need add a NFT id selector here
          The ids for the selector should come from: account.assets */}

      <ButtonGroup fullWidth>
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Send
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default SendNFTTokenForm
