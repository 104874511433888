import { Operation, TokenFormData } from '@archax/shared-types'
import { Card, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editOperation } from '../../../api/operations'
import TokenForm from './TokenForm/TokenForm'

function EditCreateTokenOperation({ operation }: { operation: Operation }) {
  const navigate = useNavigate()
  const { mutateAsync } = useMutation((formData: TokenFormData) => editOperation(operation.id, formData), {
    onSuccess: (data) => {
      toast.success('Token creation request edited with success.')
      navigate(`/tokens/requests/edit`)
    },
    onError: () => {
      toast.error('there was an error')
    },
  })

  const onSubmit = async (data: TokenFormData) => {
    await mutateAsync(data)
  }

  const defaultValues = operation.data || {}

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 7 }}>
        <Typography align="center" variant="h2">
          Edit token creation request
        </Typography>
        <TokenForm mode="edit" defaultValues={defaultValues as TokenFormData} onSubmit={onSubmit} />
      </Card>
    </Container>
  )
}

export default EditCreateTokenOperation
