"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TABLE_HEADER_NAMES = void 0;
exports.TABLE_HEADER_NAMES = {
    common: {
        address: 'Address',
        address_name: 'Address name',
        approver: 'Approver',
        balance: 'Balance',
        created_at: 'Created at',
        date_time: 'Date time',
        details: 'Details',
        email: 'Email',
        explorer_links: 'Explorer links',
        name: 'Name',
        operation_status: 'Status',
        operation_type: 'Action type',
        protocol: 'Protocol',
        requester: 'Requester',
        standard: 'Standard',
        symbol: 'Symbol',
        token_name: 'Token name',
        token_price: 'Token price',
        trader_address: 'Trader address',
        trader_name: 'Trader name',
        trader_status: 'Status',
        value: 'Value',
    },
    token_history: {
        amount: 'Tokens',
    },
    token_whitelist: {
        is_whitelisted: 'Status',
    },
    trader_addresses: {
        account_name: 'Account name',
    },
};
