import {
  EVM_CHAIN_PROTOCOLS,
  NFT_STANDARDS,
  OperationType,
  READABLE_PROTOCOL,
  READABLE_STANDARD,
  Token,
  TokenStandardType,
} from '@archax/shared-types'
import { Download, Pause, PlayArrow } from '@mui/icons-material'
import {
  Button,
  Card,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { download } from '../../../api/axios'
import { createOperation } from '../../../api/operations'
import Dialog from '../../../components/Dialog/Dialog'
import { EXPLORER_LOGOS } from '../../../constants'
import { useGlobalContext } from '../../../context'
import { hasAdminRole, hasUserRole } from '../../../util/user-roles'
import { BurnNFTTokenForm } from './BurnNFTTokenForm'
import { BurnTokenForm } from './BurnTokenForm'
import { MintTokenForm } from './MintTokenForm'

interface TokenProps {
  token: Token
}

interface TotalSupplyProps {
  totalSupply: number | undefined
}

function TotalSupply({ totalSupply }: TotalSupplyProps): React.ReactElement {
  if (!totalSupply && totalSupply !== 0) {
    return <></>
  }

  if (totalSupply >= 0) {
    return <Typography>{totalSupply}</Typography>
  }

  if (totalSupply === -1) {
    return (
      <Tooltip title="Error getting the total supply. You can try again later.">
        <Typography>{'Error'}</Typography>
      </Tooltip>
    )
  }

  return <></>
}

function TokenDetails({ token }: TokenProps) {
  const {
    state: { user },
  } = useGlobalContext()
  const { mutate: pauseMutation } = useMutation(() => createOperation(OperationType.Pause, {}, token.id), {
    onSuccess: () => {
      toast.success('Pause token request sent for approval')
    },
    onError: () => {
      toast.error('there was an error')
    },
  })

  const { mutate: unpauseMutation } = useMutation(() => createOperation(OperationType.Unpause, {}, token.id), {
    onSuccess: () => {
      toast.success('Unpause token request sent for approval')
    },
    onError: () => {
      toast.error('there was an error')
    },
  })

  const [showMintTokenDialog, setShowMintTokenDialog] = useState(false)
  const [showBurnTokenDialog, setShowBurnTokenDialog] = useState(false)
  const [showPauseTokenDialog, setShowPauseTokenDialog] = useState(false)
  const [showUnpauseTokenDialog, setShowUnpauseTokenDialog] = useState(false)

  if (!token) {
    return <></>
  }

  const isPaused = token.onChainData?.paused

  const handlePause = async () => {
    pauseMutation()
    setShowPauseTokenDialog(false)
  }

  const handleUnpause = async () => {
    unpauseMutation()
    setShowUnpauseTokenDialog(false)
  }

  const isEVMProtocol = EVM_CHAIN_PROTOCOLS.includes(token.standard.chain.protocol)
  const isNft = NFT_STANDARDS.includes(token.standard.name)
  const canMint = !isNft || (isNft && token.onChainData?.totalSupply === 0)
  const canBurn = !isNft || (isNft && token.onChainData?.balance! > 0)

  return (
    <>
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          {token.name}
        </Typography>
        <Typography align="left" variant="caption">
          <Link href={token.explorerLink?.url} rel="noreferrer" target="_blank">
            <img
              alt={`${READABLE_PROTOCOL[token.standard.chain.protocol]} explorer logo`}
              src={EXPLORER_LOGOS[token.standard.chain.protocol]}
              width="18"
            />
            {token.explorerLink?.value}
          </Link>
        </Typography>

        <Grid marginTop={3} container>
          <Grid item xs={8}>
            <TableContainer sx={{ maxWidth: 650, '& th': { border: 0 }, '& td': { border: 0 } }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Symbol</TableCell>
                    <TableCell>Protocol</TableCell>
                    <TableCell>Standard</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: '#F8FBFD' }}>
                  <TableRow>
                    <TableCell>
                      <TotalSupply totalSupply={token.onChainData?.totalSupply} />
                    </TableCell>
                    <TableCell>
                      <Typography>{token.symbol}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{READABLE_PROTOCOL[token.standard.chain.protocol]}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{READABLE_STANDARD[token.standard.name as TokenStandardType]}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="flex-end" flexDirection={'column'}>
            <Box display="flex" justifyContent="flex-end" flexDirection={'row'}>
              {hasUserRole(user) && (
                <>
                  <Box m={1}>
                    <Button disabled={!canMint} variant="contained" onClick={() => setShowMintTokenDialog(true)}>
                      Mint
                    </Button>
                  </Box>
                  <Box m={1}>
                    <Button disabled={!canBurn} variant="contained" onClick={() => setShowBurnTokenDialog(true)}>
                      Burn
                    </Button>
                  </Box>
                  <Box m={1}>
                    {!isPaused && (
                      <Button
                        sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                        variant="contained"
                        onClick={() => setShowPauseTokenDialog(true)}
                      >
                        <Pause />
                        Pause
                      </Button>
                    )}
                    {isPaused && (
                      <Button
                        sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                        variant="contained"
                        onClick={() => setShowUnpauseTokenDialog(true)}
                      >
                        <PlayArrow />
                        Unpause
                      </Button>
                    )}
                  </Box>
                </>
              )}
              {isEVMProtocol && hasAdminRole(user) && (
                <Box m={1}>
                  <Button
                    sx={{ backgroundColor: '#FFFFFF', color: '#000000' }}
                    variant="contained"
                    onClick={() => download(`tokens/${token.id}/assets`)}
                  >
                    <Download />
                    Contract
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Card>
      <Dialog
        title={isNft ? 'Mint token' : 'Mint tokens'}
        onClose={() => setShowMintTokenDialog(false)}
        open={showMintTokenDialog}
      >
        <MintTokenForm onSuccess={() => setShowMintTokenDialog(false)} tokenId={token.id} isNft={isNft} />
      </Dialog>
      <Dialog title="Burn tokens" onClose={() => setShowBurnTokenDialog(false)} open={showBurnTokenDialog}>
        {isNft && (
          <BurnNFTTokenForm
            onSuccess={() => setShowBurnTokenDialog(false)}
            tokenId={token.id}
            assets={token.onChainData?.assets!}
          />
        )}
        {!isNft && <BurnTokenForm onSuccess={() => setShowBurnTokenDialog(false)} tokenId={token.id} />}
      </Dialog>
      <Dialog
        title="Are you sure that you want to pause the token?"
        onConfirm={handlePause}
        confirmLabel="Pause"
        onClose={() => setShowPauseTokenDialog(false)}
        open={showPauseTokenDialog}
        showCancel
      >
        <Typography variant="body2">You can unpause it later.</Typography>
      </Dialog>
      <Dialog
        title="Are you sure that you want to unpause the token?"
        onConfirm={handleUnpause}
        confirmLabel="Unpause"
        onClose={() => setShowUnpauseTokenDialog(false)}
        open={showUnpauseTokenDialog}
        showCancel
      >
        <Typography variant="body2">You can pause it later.</Typography>
      </Dialog>
    </>
  )
}

export default TokenDetails
