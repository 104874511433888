import { OperationStatus, OperationType } from '@archax/shared-types'
import qs from 'qs'
import { axios } from './axios'
import { PaginationRequestParams } from './types'

export const getOperation = (id: string) => {
  return axios.get(`/operations/${id}`)
}

export const createOperation = (type: OperationType, data: Object, tokenId?: string, traderId?: string) => {
  return axios.post('/operations', { type, data, tokenId, traderId })
}

export const editOperation = (id: string, data: Object) => {
  return axios.put(`/operations/${id}`, data)
}

export const approveOperation = (id: string, version: number) => {
  return axios.post(`/operations/${id}/approve`, { version })
}

export const cancelOperation = (id: string) => {
  return axios.post(`/operations/${id}/cancel`)
}

export const rejectOperation = (id: string, version: number) => {
  return axios.post(`/operations/${id}/reject`, { version })
}

interface GetOperationsParams extends PaginationRequestParams {
  status?: OperationStatus[]
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}
export const getOperationsToApprove = (params: GetOperationsParams) => {
  const { sort, ...rest } = params
  const queryObj = {
    status: [OperationStatus.PendingApproval, OperationStatus.InProgress],
    ...rest,
    ...(sort && { sort: `${sort.field},${sort.direction}` }),
  }
  const querystring = qs.stringify(queryObj, { indices: false })

  return axios.get(`/operations?${querystring}`)
}

interface GetMyOperationsParams extends PaginationRequestParams {
  status?: OperationStatus[]
  type?: OperationType[]
}

export const getMyOperations = (params: GetMyOperationsParams) => {
  params.type = [OperationType.CreateToken]
  params.status = [OperationStatus.PendingApproval]
  const querystring = qs.stringify(params, { indices: false })

  return axios.get(`/me/operations?${querystring}`)
}
