import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel: React.FunctionComponent<TabPanelProps> = ({ children, value, index, ...other }) => {
  const [visited, setVisited] = useState(false)
  useEffect(() => {
    if (value === index) {
      setVisited(true)
    }
  }, [value, index])

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {visited && <Box sx={{ paddingTop: 3 }}>{children}</Box>}
    </div>
  )
}

export default TabPanel
