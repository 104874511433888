import { ChainProtocol, READABLE_PROTOCOL, TABLE_HEADER_NAMES, TraderCapTableData } from '@archax/shared-types'
import { CircularProgress, Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useQuery } from '@tanstack/react-query'
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useMemo } from 'react'
import { toast } from 'react-toastify'
import { downloadCSV } from '../../api/axios'
import { getTraderCapTable, getTraderCapTableCSVUrl } from '../../api/traders'
import DownloadIcon from '../../components/DownloadIcon/DownloadIcon'
import currencyFormatter from '../../util/currency-formatter'

interface TraderCapTableProps {
  id: string
}

function TraderCapTable({ id }: TraderCapTableProps) {
  const { isLoading, data } = useQuery(
    [`get-trader-${id}-cap-table`],
    () =>
      getTraderCapTable({
        id,
      }),
    {
      retry: false,
      onError: () => {
        toast.error('there was an error')
      },
    },
  )

  const { data: holdings } = data?.data || {}

  const columnDefs: ColDef<TraderCapTableData>[] = useMemo(
    () => [
      {
        field: 'tokenName',
        headerName: TABLE_HEADER_NAMES.common.token_name,
        flex: 1,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'traderAddressName',
        valueGetter: (params: ValueGetterParams<TraderCapTableData>) => {
          return `${params.data!.traderAddressName} (${params.data!.traderAddress})`
        },
        headerName: TABLE_HEADER_NAMES.common.address,
        flex: 1,
        minWidth: 200,
        sortable: false,
        tooltipValueGetter: (params) => {
          return `${params.data!.traderAddressName} (${params.data!.traderAddress})`
        },
      },
      {
        field: 'protocol',
        valueGetter: (params: ValueGetterParams<TraderCapTableData>) => {
          return READABLE_PROTOCOL[params.data!.chain.protocol as ChainProtocol]
        },
        headerName: TABLE_HEADER_NAMES.common.protocol,
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'balance',
        headerName: TABLE_HEADER_NAMES.common.balance,
        flex: 1,
        minWidth: 100,
        sortable: false,
      },
      {
        field: 'tokenPrice',
        headerName: TABLE_HEADER_NAMES.common.token_price,
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueGetter: (params: ValueGetterParams<TraderCapTableData>) => {
          return currencyFormatter(params.data!.tokenPrice, { prefix: params.data!.tokenCurrency, decimals: 2 })
        },
      },
      {
        field: 'value',
        headerName: TABLE_HEADER_NAMES.common.value,
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueGetter: (params: ValueGetterParams<TraderCapTableData>) => {
          return currencyFormatter(params.data!.value, { prefix: params.data!.tokenCurrency, decimals: 2 })
        },
      },
    ],
    [],
  )

  const handleCSVDownload = () => downloadCSV(getTraderCapTableCSVUrl(id))

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: holdings,
      domLayout: 'autoHeight',
    }),
    [columnDefs, holdings],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          Cap table
        </Typography>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <DownloadIcon onClick={handleCSVDownload}></DownloadIcon>
        </Box>
      </Box>
      {isLoading && <CircularProgress />}
      {!isLoading && holdings && (
        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
          {holdings.length === 0 && <Typography>No holdings</Typography>}
          {holdings.length > 0 && (
            <Box width={'100%'} className="ag-theme-material">
              <AgGridReact {...gridOptions}></AgGridReact>
            </Box>
          )}
          <Divider />
        </Box>
      )}
    </div>
  )
}

export default TraderCapTable
