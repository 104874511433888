import {
  AddTraderAddressOperationData,
  ChainProtocol,
  Operation,
  OperationType,
  READABLE_OPERATION_TYPE,
  READABLE_PROTOCOL,
  TABLE_HEADER_NAMES,
  TraderAddress,
} from '@archax/shared-types'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community'
import { useMemo } from 'react'
import { getTraderHistory, getTraderHistoryCSVUrl } from '../../../api/traders'
import { ServerSideGrid } from '../../../components/ServerSideGrid'

interface TraderHistoryProps {
  id: string
}

interface TraderHistoryData extends Operation {
  addressName?: string
  address?: string
  traderAddress?: TraderAddress
}

function formatDetails(data: TraderHistoryData): string {
  let operationData
  switch (data.type) {
    case OperationType.AddTraderAddress:
      operationData = data.data as AddTraderAddressOperationData
      return `Protocol: ${READABLE_PROTOCOL[operationData.protocol as ChainProtocol]}, name: ${
        operationData.addressName
      }, address: ${operationData.address}`
    case OperationType.RemoveTraderAddress:
      return `Name: ${data.traderAddress?.name}, address: ${data.traderAddress?.address}`
    case OperationType.GrantKYC:
      return `Address name: ${data.traderAddress?.name}, token id: ${data.tokenId}`
    case OperationType.RevokeKYC:
      return `Address name: ${data.traderAddress?.name}, token id: ${data.tokenId}`
    default:
      return ''
  }
}

function TraderHistory({ id }: TraderHistoryProps) {
  const columnDefs: ColDef<Operation>[] = useMemo(
    () => [
      {
        field: 'approvedAt',
        headerName: TABLE_HEADER_NAMES.common.date_time,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          return new Date(params.data!.approvedAt).toLocaleDateString('en-UK', { hour: '2-digit', minute: '2-digit' })
        },
        flex: 1,
        maxWidth: 160,
        sortable: false,
      },
      {
        field: 'type',
        headerName: TABLE_HEADER_NAMES.common.operation_type,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          return READABLE_OPERATION_TYPE[params.data!.type as OperationType]
        },
        flex: 1,
        sortable: false,
      },
      {
        field: 'details',
        headerName: TABLE_HEADER_NAMES.common.details,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          return formatDetails(params.data!)
        },
        flex: 3,
        sortable: false,
      },
    ],
    [],
  )

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      defaultColDef: { filter: false },
    }),
    [columnDefs],
  )

  return (
    <div>
      <Box
        sx={{ paddingLeft: 0 }}
        maxWidth="xl"
        flexDirection={'row'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography align="left" variant="h5">
          History
        </Typography>
      </Box>
      <Box>
        <ServerSideGrid
          gridOptions={gridOptions}
          queryFn={(gridParams) => getTraderHistory({ ...gridParams, id })}
          csvExportUrlGetter={(gridParams) => getTraderHistoryCSVUrl({ ...gridParams, id })}
        />
      </Box>
    </div>
  )
}

export default TraderHistory
