import { AxiosResponse } from 'axios'
import qs from 'qs'
import { axios } from './axios'
import { PaginationRequestParams } from './types'

export interface GetOrganizationsParams extends PaginationRequestParams {
  name?: string
  created?: string
  sort?: {
    field: string
    direction: 'asc' | 'desc'
  } | null
}

export const getOrganizations = (params?: GetOrganizationsParams) => {
  if (params) {
    const { sort, ...rest } = params
    const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
    const querystring = qs.stringify(queryObj, { indices: false })
    return axios.get(`/organizations?${querystring}`)
  }
  // TODO: fix select on user creation to paginate the dropdown list, based in scroll
  // ARX-321: https://appliedblockchain.atlassian.net/browse/ARX-321
  return axios.get(`/organizations?offset=0&limit=100`)
}

export interface OrganizationCreateParams {
  name: string
  logo?: any
  logoHasTransparentBackground?: boolean
}

export const createOrganizations = (params: OrganizationCreateParams) => {
  const formData = new FormData()
  formData.append('name', params.name)
  if (params.logo && params.logo[0]) {
    formData.append('logo', params.logo[0])
  }
  formData.append('logoHasTransparentBackground', params.logoHasTransparentBackground ? 'true' : 'false')

  return axios.post(`/organizations`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const editOrganization = (id: string, params: Omit<OrganizationCreateParams, 'name'>) => {
  const formData = new FormData()
  if (typeof params.logo !== 'string' && params.logo && params.logo[0]) {
    formData.append('logo', params.logo[0])
  }
  formData.append('logoHasTransparentBackground', params.logoHasTransparentBackground ? 'true' : 'false')

  return axios.patch(`/organizations/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

interface GetOrganizationByIdResponse {
  name: string
  logoId: string
  logoHasTransparentBackground: boolean
  createdAt: string
}

export const getOrganizationById = (id: string): Promise<AxiosResponse<GetOrganizationByIdResponse>> => {
  return axios.get(`/organizations/${id}`)
}
