import { Box } from '@mui/material'
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon'
import {
  ColDef,
  GridApi,
  GridOptions,
  IServerSideGetRowsParams,
  ISimpleFilterModel,
  SortModelItem,
} from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { AxiosResponse } from 'axios'
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { downloadCSV } from '../../api/axios'
import { DEFAULT_PAGE_SIZE } from '../../constants'
import DownloadIcon from '../DownloadIcon/DownloadIcon'

export interface ServerSideGridProps {
  gridOptions?: Partial<GridOptions>
  queryFn: (params: any) => Promise<AxiosResponse<any, any>>
  csvExportUrlGetter?: (params: any) => string
  setParentGridApi?: Dispatch<SetStateAction<GridApi | null>>
  mutateFilters?: (filters: any) => any
}

const ServerSideGrid: React.FC<ServerSideGridProps> = ({
  gridOptions,
  queryFn,
  csvExportUrlGetter,
  setParentGridApi,
  mutateFilters,
}): React.ReactElement => {
  const { onGridReady: parentOnGridReady, ...restGridOptions } = gridOptions || {}
  const [gridApi, setGridApi] = useState<null | GridApi>(null)

  const buildFilters = useCallback(
    (filterModel: ISimpleFilterModel): object => {
      const filters = Object.entries(filterModel).reduce((acc, [paramName, filterParams]) => {
        const { filter: value, filterTo: valueTo, values, filterType, type, dateFrom, dateTo } = filterParams
        if (filterType === 'date') {
          if (type === 'inRange') {
            acc[`${paramName}[gte]`] = dateFrom
            acc[`${paramName}[lte]`] = dateTo
          }
          if (type === 'lessThanOrEqual') {
            acc[`${paramName}[lte]`] = dateFrom
          }
          if (type === 'greaterThanOrEqual') {
            acc[`${paramName}[gte]`] = dateFrom
          }
        } else if (filterType === 'number') {
          if (type === 'inRange') {
            acc[`${paramName}[gte]`] = value
            acc[`${paramName}[lte]`] = valueTo
          }
          if (type === 'lessThanOrEqual') {
            acc[`${paramName}[lte]`] = value
          }
          if (type === 'greaterThanOrEqual') {
            acc[`${paramName}[gte]`] = value
          }
        } else {
          acc[paramName] = value || values
        }

        return acc
      }, {} as { [key: string]: string | string[] })

      return mutateFilters ? mutateFilters(filters) : filters
    },
    [mutateFilters],
  )

  const buildSort = useCallback((sortModel: SortModelItem[]) => {
    if (!sortModel.length) {
      return null
    }
    const { colId: field, sort: direction } = sortModel[0]
    return { field, direction }
  }, [])

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 90,
      resizable: true,
      filter: true,
      floatingFilter: true,
      ...gridOptions?.defaultColDef,
    }),
    [gridOptions?.defaultColDef],
  )

  const dataSource = useMemo(
    () => ({
      getRows: async (params: IServerSideGetRowsParams) => {
        const { startRow, endRow, filterModel, sortModel } = params.request
        let sort = buildSort(sortModel)
        const filters = buildFilters(filterModel)
        const response = await queryFn({ offset: startRow, limit: endRow, ...(sort && { sort }), ...filters })
        const body = response.data
        if (response.status === 200) {
          params.success({
            rowData: body.data,
            rowCount: body.total,
          })
        } else {
          params.fail()
        }
      },
    }),
    [queryFn, buildSort, buildFilters],
  )

  const handleCSVDownload = () => {
    const sort = buildSort(gridApi!['serverSideRowModel'].storeParams.sortModel)
    const filters = buildFilters(gridApi!.getFilterModel())
    const csvUrl = csvExportUrlGetter!({ ...(sort && { sort }), ...filters })
    return downloadCSV(csvUrl)
  }

  const onGridReady = useCallback(
    (params: AgGridCommon<{}, {}>) => {
      if (!gridApi) {
        setGridApi(params.api)
        setParentGridApi && setParentGridApi(params.api)
      }
      if (parentOnGridReady) {
        parentOnGridReady(params as any)
      }
    },
    [gridApi, setParentGridApi],
  )

  const defaultGridOptions: GridOptions = useMemo(
    () => ({
      rowModelType: 'serverSide',
      domLayout: 'autoHeight',
      defaultColDef,
      pagination: true,
      paginationPageSize: DEFAULT_PAGE_SIZE,
      cacheBlockSize: DEFAULT_PAGE_SIZE,
      maxBlocksInCache: 1,
      onGridReady,
    }),
    [onGridReady, defaultColDef],
  )

  return (
    <Box width={'100%'} height={'100%'}>
      {csvExportUrlGetter && (
        <Box display={'flex'} justifyContent="flex-end">
          <DownloadIcon onClick={handleCSVDownload}></DownloadIcon>
        </Box>
      )}
      <Box width={'100%'} height={'100%'} className="ag-theme-material">
        <AgGridReact serverSideDatasource={dataSource} {...defaultGridOptions} {...restGridOptions}></AgGridReact>
      </Box>
    </Box>
  )
}

export default ServerSideGrid
