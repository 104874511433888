import {
  APIErrorResponse,
  ChainProtocol,
  CreateTokenOperationData,
  Operation,
  OperationType,
  READABLE_OPERATION_TYPE,
  READABLE_PROTOCOL,
  READABLE_STANDARD,
  TokenStandardType,
} from '@archax/shared-types'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Card, IconButton, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Container } from '@mui/system'
import { ColDef, GridApi, GridOptions, ICellRendererParams, ValueGetterParams } from 'ag-grid-community'
import { AxiosError } from 'axios'
import { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { approveOperation, getOperationsToApprove, rejectOperation } from '../../api/operations'
import Dialog from '../../components/Dialog/Dialog'
import { ServerSideGrid } from '../../components/ServerSideGrid'

interface ApprovalRow {
  id: string
  tokenName: string
  action: string
  requestor: string
  protocol: string
  standard: string
  status: string
  version: number
}

interface SelectedRow {
  row: ApprovalRow | null
  api: GridApi | null
}

function AdminApproval() {
  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [showRejectDialog, setShowRejectDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<SelectedRow>({
    row: null,
    api: null,
  })

  const handleApprove = async ({ row, api }: SelectedRow) => {
    try {
      await approveOperation(row!.id, row!.version)
      toast.success('Request approved. The operation is now being processed.')
      api?.refreshServerSide()
      setShowApproveDialog(false)
    } catch (error) {
      toast.error((error as AxiosError<APIErrorResponse>).response?.data.error.message || 'there was an error')
    }
  }

  const handleReject = async ({ row, api }: SelectedRow) => {
    try {
      await rejectOperation(row!.id, row!.version)
      toast.success('Request rejected')
      api?.refreshServerSide()
      setShowRejectDialog(false)
    } catch (error) {
      toast.error('there was an error')
    }
  }

  const columnDefs: ColDef<Operation>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        width: 300,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          return operation.type === OperationType.CreateToken
            ? (operation.data as CreateTokenOperationData).name
            : operation.token?.name
        },
      },
      {
        field: 'action',
        headerName: 'Action',
        width: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          return READABLE_OPERATION_TYPE[params.data!.type]
        },
      },
      {
        field: 'requestor.name',
        headerName: 'Creator',
        width: 150,
        sortable: false,
      },
      {
        field: 'protocol',
        headerName: 'Protocol',
        width: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          return operation.type === OperationType.CreateToken
            ? READABLE_PROTOCOL[(operation.data as CreateTokenOperationData).protocol as ChainProtocol]
            : READABLE_PROTOCOL[operation.token?.standard.chain.protocol as ChainProtocol]
        },
      },
      {
        field: 'standard',
        headerName: 'Standard',
        width: 150,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Operation>) => {
          const operation = params.data!
          return operation.type === OperationType.CreateToken
            ? READABLE_STANDARD[(operation.data as CreateTokenOperationData).standard as TokenStandardType]
            : READABLE_STANDARD[operation.token?.standard.name as TokenStandardType]
        },
      },
      {
        field: 'actions',
        width: 200,
        sortable: false,
        cellRenderer: (params: ICellRendererParams<ApprovalRow>) => {
          return (
            <Stack direction={'row'}>
              <IconButton
                aria-label="Approve"
                onClick={() => {
                  setSelectedRow({ row: params.data!, api: params.api })
                  setShowApproveDialog(true)
                }}
                sx={{ '&:hover': { backgroundColor: 'success.light' } }}
              >
                <CheckCircleIcon sx={{ color: 'success.main' }} />
              </IconButton>
              <IconButton
                aria-label="Reject"
                onClick={() => {
                  setSelectedRow({ row: params.data!, api: params.api })
                  setShowRejectDialog(true)
                }}
                sx={{ '&:hover': { backgroundColor: 'error.light' } }}
              >
                <CancelIcon sx={{ color: 'error.main' }} />
              </IconButton>
            </Stack>
          )
        },
      },
    ],
    [],
  )

  // useEffect(() => {
  //   // While there's at least one operarion in progress we keep checking
  //   const inProgressOperation = operations.find(
  //     (operation: Operation) => operation.status === OperationStatus.InProgress,
  //   )

  //   const timeoutId = inProgressOperation ? setTimeout(refetch, 2000) : undefined

  //   return () => clearTimeout(timeoutId)
  // }, [operations, refetch])

  const gridOptions: GridOptions = {
    columnDefs,
    defaultColDef: { filter: false },
  }

  return (
    <Container maxWidth="xl">
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          Admin approval
        </Typography>
        <Typography align="left">Manage requests of other users</Typography>
        <Box>
          <ServerSideGrid gridOptions={gridOptions} queryFn={getOperationsToApprove} />
        </Box>
      </Card>
      <Dialog
        title="Are you sure that you want to approve the request?"
        onConfirm={() => handleApprove(selectedRow!)}
        confirmLabel="Approve"
        onClose={() => setShowApproveDialog(false)}
        open={showApproveDialog}
        showCancel
      >
        <Typography variant="body2"></Typography>
      </Dialog>
      <Dialog
        title="Are you sure that you want to reject the request?"
        onConfirm={() => handleReject(selectedRow!)}
        confirmLabel="Reject"
        onClose={() => setShowRejectDialog(false)}
        open={showRejectDialog}
        showCancel
      >
        <Typography variant="body2"></Typography>
      </Dialog>
    </Container>
  )
}

export default AdminApproval
