import {
  Currency,
  Holding,
  PriceType,
  READABLE_DATE_FORMAT,
  READABLE_PRICE_TYPE,
  TABLE_HEADER_NAMES,
  Token,
} from '@archax/shared-types'
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useQuery } from '@tanstack/react-query'
import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { downloadCSV } from '../../../api/axios'
import { getTokenCapTable, getTokenCapTableCSVUrl } from '../../../api/tokens'
import Dialog from '../../../components/Dialog/Dialog'
import DownloadIcon from '../../../components/DownloadIcon/DownloadIcon'
import currencyFormatter from '../../../util/currency-formatter'
import { AddPriceForm } from './AddPriceForm'

interface TokenCapTableProps {
  token: Token
}

function TokenCapTable({ token: { id, currency, createdAt } }: TokenCapTableProps) {
  const [date, setDate] = useState<null | Dayjs>(null)
  const [dateQueryKey, setDateQueryKey] = useState<null | Dayjs>(date)
  const [showAddPriceDialog, setShowAddPriceDialog] = useState(false)
  const timestamp = date ? date.unix() : null
  const { isLoading, data, refetch } = useQuery(
    ['token-cap-table', id, dateQueryKey],
    () =>
      getTokenCapTable({
        id,
        timestamp,
      }),
    {
      retry: false,
      onError: () => {
        toast.error('there was an error')
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  useEffect(() => {
    const refetchTimeout = setTimeout(() => {
      setDateQueryKey(date)
    }, 1000)

    return () => clearTimeout(refetchTimeout)
  }, [date])

  const { price, data: holdings } = data?.data || {}

  const columnDefs: ColDef<Holding>[] = useMemo(
    () => [
      {
        field: 'traderName',
        headerName: TABLE_HEADER_NAMES.common.trader_name,
        flex: 1,
        minWidth: 130,
        sortable: false,
      },
      {
        field: 'traderAddressName',
        valueGetter: (params: ValueGetterParams<Holding>) => {
          return `${params.data!.traderAddressName} (${params.data!.traderAddress})`
        },
        headerName: TABLE_HEADER_NAMES.common.address,
        flex: 1,
        minWidth: 450,
        sortable: false,
        tooltipValueGetter: (params) => {
          return `${params.data!.traderAddressName} (${params.data!.traderAddress})`
        },
      },
      {
        field: 'balance',
        headerName: TABLE_HEADER_NAMES.common.balance,
        flex: 1,
        minWidth: 50,
        sortable: false,
      },
      {
        field: 'value',
        headerName: `${TABLE_HEADER_NAMES.common.value} (${currency})`,
        flex: 1,
        minWidth: 100,
        sortable: false,
        valueGetter: (params: ValueGetterParams<Holding>) => {
          return currencyFormatter(params.data!.value!, { decimals: 2 })
        },
      },
    ],
    [currency],
  )

  const handleCSVDownload = () => downloadCSV(getTokenCapTableCSVUrl({ id, timestamp }))

  const gridOptions: GridOptions = useMemo(
    () => ({
      columnDefs,
      rowData: holdings,
      domLayout: 'autoHeight',
    }),
    [columnDefs, holdings],
  )

  return (
    <div>
      <Box display={'flex'} alignItems={'center'} flexWrap={'wrap'} justifyContent={'space-between'}>
        <Box marginBottom={2}>
          <DateTimePicker
            format={READABLE_DATE_FORMAT}
            value={date ?? dayjs(date)}
            label="Date"
            minDateTime={dayjs(createdAt)}
            onChange={(newDate) => {
              if (!newDate || !newDate?.isValid()) {
                return
              }
              setDate(newDate)
            }}
            slotProps={{ textField: { variant: 'standard', fullWidth: true, style: { marginBottom: 4 } } }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            setShowAddPriceDialog(true)
          }}
        >
          Add price
        </Button>
      </Box>

      {isLoading && <CircularProgress />}
      {!isLoading && data && (
        <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
          <Grid item xs={8}>
            <TableContainer sx={{ '& th': { border: 0 }, '& td': { border: 0 } }}>
              <Table aria-label="cap table">
                <TableHead>
                  <TableRow>
                    <TableCell>Effective price date</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ backgroundColor: '#F8FBFD' }}>
                  <TableRow>
                    <TableCell>
                      <Typography>{price ? dayjs(price.effectiveDate).format(READABLE_DATE_FORMAT) : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {price ? currencyFormatter(price.price, { prefix: currency, decimals: 2 }) : '-'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{price ? READABLE_PRICE_TYPE[price.type as PriceType] : '-'}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{price && price.notes ? price.notes : '-'}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Box
            paddingLeft={0}
            maxWidth="xl"
            flexDirection={'row'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            marginBottom={4}
            marginTop={4}
          >
            <Typography align="left" variant="h5">
              Cap table
            </Typography>

            <DownloadIcon onClick={handleCSVDownload}></DownloadIcon>
          </Box>

          <Box sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}>
            {holdings.length === 0 && <Typography>No holdings</Typography>}
            {holdings.length > 0 && (
              <Box width={'100%'} className="ag-theme-material">
                <AgGridReact {...gridOptions}></AgGridReact>
              </Box>
            )}
            <Divider />
          </Box>
        </Box>
      )}
      <Dialog title={'Add price'} onClose={() => setShowAddPriceDialog(false)} open={showAddPriceDialog}>
        <AddPriceForm
          onClose={() => setShowAddPriceDialog(false)}
          onSuccess={() => {
            refetch()
            setShowAddPriceDialog(false)
          }}
          tokenId={id}
          minEffectiveDateTime={createdAt}
          currency={currency}
        />
      </Dialog>
    </div>
  )
}

export default TokenCapTable
