import { ChainProtocol, Currency, READABLE_PROTOCOL, TokenFormData, TokenStandardType } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import Select, { SelectOption } from '../../../../components/ui/Select/Select'
import TextField from '../../../../components/ui/TextField/TextField'
import { STANDARDS_WITH_PREMINT } from '../../../../constants'

const validationSchema = Yup.object()
  .shape({
    name: Yup.string().max(20).required(),
    symbol: Yup.string().max(6).required(),
    protocol: Yup.string().required(),
    standard: Yup.string().required(),
    numberToCreate: Yup.number()
      .transform((a, value) => {
        if (!value) {
          return 0
        }
        return isNaN(value) ? '' : Number(value)
      })
      .integer('Number to create must be a non-negative integer.')
      .min(0, 'Number to create must be a non-negative integer.')
      .typeError('Number to create must be a non-negative integer.')
      .optional()
      .nullable(),
    notes: Yup.string().max(100),
    currency: Yup.string().oneOf(Object.values(Currency)).required(),
  })
  .required()

const initialValues = {
  name: '',
  symbol: '',
  protocol: '',
  standard: undefined,
  numberToCreate: '',
  notes: '',
  currency: '',
}

const algorandStandardOptions = [{ label: 'ASA', value: TokenStandardType.ASA }]

const evmStandardOptions: SelectOption[] = [
  { label: 'ERC20', value: TokenStandardType.ERC20 },
  { label: 'ERC721', value: TokenStandardType.ERC721 },
]

const hederaTokenStandardOptions = [
  { label: 'Fungible', value: TokenStandardType.FungibleCommon },
  { label: 'Non-fungible', value: TokenStandardType.NonFungibleUnique },
]

const protocolStandardOptionsMap = {
  [ChainProtocol.Algorand]: algorandStandardOptions,
  [ChainProtocol.Ethereum]: evmStandardOptions,
  [ChainProtocol.Hedera]: hederaTokenStandardOptions,
  [ChainProtocol.Polygon]: evmStandardOptions,
}

interface TokenFormProps {
  mode: 'edit' | 'create'
  onSubmit: (data: TokenFormData) => Promise<void>
  defaultValues?: TokenFormData
}

function TokenForm({ onSubmit, defaultValues, mode }: TokenFormProps) {
  const navigate = useNavigate()
  const [standardOptions, setStandardOptions] = useState<SelectOption[]>([])
  const [showPremintField, setShowPremintField] = useState(false)

  const submitButtonLabel = mode === 'create' ? 'Create' : 'Update'

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid, isSubmitting },
    reset,
  } = useForm<TokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || initialValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (mode === 'edit') {
      const newOptions = protocolStandardOptionsMap[defaultValues?.protocol as ChainProtocol]
      setStandardOptions(newOptions)
    }
  }, [defaultValues, mode])

  useEffect(() => {
    watch((value, { name }) => {
      if (name === 'protocol') {
        const newOptions = protocolStandardOptionsMap[value.protocol as ChainProtocol]
        setStandardOptions(newOptions)
      }
      if (name === 'standard') {
        const allowPremint = STANDARDS_WITH_PREMINT.includes(value.standard as TokenStandardType)
        setShowPremintField(allowPremint)
      }
    })
  }, [watch])

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        await onSubmit(data)
        reset()
      })}
    >
      <TextField name="name" control={control} label="Name" />
      <TextField name="symbol" control={control} label="Symbol" />
      <Select
        name="protocol"
        control={control}
        label="Protocol"
        options={[
          { label: READABLE_PROTOCOL[ChainProtocol.Algorand], value: ChainProtocol.Algorand },
          { label: READABLE_PROTOCOL[ChainProtocol.Ethereum], value: ChainProtocol.Ethereum },
          { label: READABLE_PROTOCOL[ChainProtocol.Hedera], value: ChainProtocol.Hedera },
          { label: READABLE_PROTOCOL[ChainProtocol.Polygon], value: ChainProtocol.Polygon },
        ]}
      />
      <Select name="standard" control={control} label="Standard" options={standardOptions} />
      <Select
        name="currency"
        control={control}
        label="Currency"
        options={Object.values(Currency).map((currencyCode) => ({
          label: currencyCode,
          value: currencyCode,
        }))}
      />
      {showPremintField && (
        <TextField name="numberToCreate" type="number" control={control} label="Number to create (optional)" />
      )}

      <TextField name="notes" control={control} label="Notes (optional)" />

      <ButtonGroup fullWidth>
        {mode === 'edit' && (
          <Button
            size="large"
            fullWidth
            onClick={() => {
              navigate('/tokens/requests/edit')
            }}
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={!isDirty || !isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          {submitButtonLabel}
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default TokenForm
