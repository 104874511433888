import React from 'react'
import { Controller } from 'react-hook-form'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'

export interface SelectOption {
  label?: String
  value: string | number
}

export type FormSelectProps = MuiSelectProps & {
  name: string
  control: any
  options: SelectOption[]
}

const FormSelect: React.FunctionComponent<FormSelectProps> = ({
  name,
  control,
  label,
  options,
  ...other
}): React.ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <MuiSelect
            sx={{ mb: 4 }}
            fullWidth
            label={label}
            labelId={`${name}-label`}
            variant="standard"
            onChange={onChange}
            value={value}
            {...other}
          >
            {options &&
              options.map((option: SelectOption, idx: number) => {
                return (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                )
              })}
          </MuiSelect>
        </>
      )}
    />
  )
}

export default FormSelect
