import { BurnNFTTokenFormData, NFTInfo, OperationType } from '@archax/shared-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { createOperation } from '../../../../api/operations'

interface BurnNFTTokenProps {
  tokenId: string
  onSuccess: () => void
  assets: NFTInfo[]
}

const validationSchema = Yup.object()
  .shape({
    tokenId: Yup.number()
      .positive()
      .integer('NFT id must be a positive number.')
      .min(0, 'NFT id must be a positive number.')
      .typeError('NFT id must be a positive number.'),
  })
  .required()

function BurnNFTTokenForm({ tokenId, onSuccess, assets }: BurnNFTTokenProps) {
  const initialValues = { tokenId: assets?.[0].tokenId || 0 }
  const { mutate } = useMutation(
    (formData: BurnNFTTokenFormData) => createOperation(OperationType.Burn, formData, tokenId),
    {
      onSuccess: (data) => {
        toast.success('Burn token request sent for approval')
      },
      onError: () => {
        toast.error('there was an error')
      },
    },
  )

  const onSubmit = async (data: BurnNFTTokenFormData) => {
    try {
      await mutate(data)
      onSuccess()
    } catch (error: any) {}
  }

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<BurnNFTTokenFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Later on if we move to collections we need add a NFT id selector here
          The ids for the selector should come from: assets */}

      <ButtonGroup fullWidth>
        <Button
          disabled={!isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Burn
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default BurnNFTTokenForm
