"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NFT_STANDARDS = exports.TokenStandardType = exports.TokenStandardStatus = void 0;
var TokenStandardStatus;
(function (TokenStandardStatus) {
    TokenStandardStatus["Enabled"] = "ENABLED";
    TokenStandardStatus["Disabled"] = "DISABLED";
})(TokenStandardStatus = exports.TokenStandardStatus || (exports.TokenStandardStatus = {}));
var TokenStandardType;
(function (TokenStandardType) {
    TokenStandardType["ERC20"] = "ERC20";
    TokenStandardType["ERC721"] = "ERC721";
    TokenStandardType["FungibleCommon"] = "FUNGIBLE_COMMON";
    TokenStandardType["NonFungibleUnique"] = "NON_FUNGIBLE_UNIQUE";
    TokenStandardType["ASA"] = "ASA";
})(TokenStandardType = exports.TokenStandardType || (exports.TokenStandardType = {}));
exports.NFT_STANDARDS = [TokenStandardType.ERC721, TokenStandardType.NonFungibleUnique];
