import { UserFormData } from '@archax/shared-types'
import qs from 'qs'
import { axios } from './axios'
import { PaginationRequestParams } from './types'

interface GetUsersParams extends PaginationRequestParams {
  name?: string
  email?: string
  sort: {
    field: string
    direction: 'asc' | 'desc'
  }
}

export const getUsersCSVUrl = (params: GetUsersParams) => {
  const { sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })
  return `/users?${querystring}`
}

export const getUsers = (params: GetUsersParams) => {
  const { sort, ...rest } = params
  const queryObj = { ...rest, ...(sort && { sort: `${sort.field},${sort.direction}` }) }
  const querystring = qs.stringify(queryObj, { indices: false })
  return axios.get(`/users?${querystring}`)
}

export const deleteUser = (id: string) => {
  return axios.delete(`/users/${id}`)
}

export const createUser = (details: UserFormData) => {
  return axios.post(`/users`, details)
}

export const getMe = () => {
  return axios.get(`/me`)
}
