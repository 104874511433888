"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OperationProcessStatus = exports.OperationStatus = exports.OperationTransactionDescription = exports.OperationType = void 0;
__exportStar(require("./errors"), exports);
var OperationType;
(function (OperationType) {
    OperationType["CreateToken"] = "CREATE_TOKEN";
    OperationType["Mint"] = "MINT";
    OperationType["Send"] = "SEND";
    OperationType["Burn"] = "BURN";
    OperationType["Wipe"] = "WIPE";
    OperationType["GrantKYC"] = "GRANT_KYC";
    OperationType["RevokeKYC"] = "REVOKE_KYC";
    OperationType["Pause"] = "PAUSE";
    OperationType["Unpause"] = "UNPAUSE";
    OperationType["AddTraderAddress"] = "ADD_TRADER_ADDRESS";
    OperationType["RemoveTraderAddress"] = "REMOVE_TRADER_ADDRESS";
})(OperationType = exports.OperationType || (exports.OperationType = {}));
var OperationTransactionDescription;
(function (OperationTransactionDescription) {
    OperationTransactionDescription["Mint"] = "MINT";
    OperationTransactionDescription["Transfer"] = "TRANSFER";
})(OperationTransactionDescription = exports.OperationTransactionDescription || (exports.OperationTransactionDescription = {}));
var OperationStatus;
(function (OperationStatus) {
    OperationStatus["PendingApproval"] = "PENDING_APPROVAL";
    OperationStatus["InProgress"] = "IN_PROGRESS";
    OperationStatus["Approved"] = "APPROVED";
    OperationStatus["Cancelled"] = "CANCELLED";
    OperationStatus["NotApproved"] = "NOT_APPROVED";
})(OperationStatus = exports.OperationStatus || (exports.OperationStatus = {}));
var OperationProcessStatus;
(function (OperationProcessStatus) {
    OperationProcessStatus["Success"] = "SUCCESS";
    OperationProcessStatus["Error"] = "ERROR";
    OperationProcessStatus["Pending"] = "PENDING";
})(OperationProcessStatus = exports.OperationProcessStatus || (exports.OperationProcessStatus = {}));
