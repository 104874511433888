import { Card, Container, Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createOrganizations, OrganizationCreateParams } from '../../api/organizations'
import OrganizationsForm from './components/OrganizationsForm'

function CreateOrganizations() {
  const navigate = useNavigate()
  const { mutate } = useMutation((formData: OrganizationCreateParams) => createOrganizations(formData), {
    onSuccess: (data) => {
      toast.success('Organization created successfully')
      navigate('/organizations')
    },
    onError: () => {
      toast.error('There was an error')
    },
  })

  const onSubmit = async (data: OrganizationCreateParams) => {
    try {
      mutate(data)
    } catch (error: any) {}
  }

  return (
    <Container>
      <Card sx={{ p: 7 }}>
        <Typography align="left" variant="h3">
          New Organization
        </Typography>
        <OrganizationsForm onSubmit={onSubmit} mode="create" />
      </Card>
    </Container>
  )
}

export default CreateOrganizations
