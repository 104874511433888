import {
  AddressCreationFormData,
  AddTraderAddressOperationData,
  APIErrorResponse,
  OperationType,
  READABLE_PROTOCOL,
} from '@archax/shared-types'
import { isValidAddress } from '@archax/address-validator'
import { yupResolver } from '@hookform/resolvers/yup'
import { ButtonGroup } from '@mui/material'
import Button from '@mui/material/Button'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { ChainProtocol } from '@archax/shared-types'
import Select from '../../../../components/ui/Select/Select'
import { createOperation } from '../../../../api/operations'
import TextField from '../../../../components/ui/TextField/TextField'
import { AxiosError } from 'axios'

interface AddAddressProps {
  traderId: string
  onSuccess: () => void
}

const validationSchema = Yup.object()
  .shape({
    name: Yup.string().required().max(100),
    address: Yup.string()
      .test('is-valid-address', 'Invalid address', (value: string | undefined, ctx: Yup.TestContext): boolean => {
        const { protocol } = ctx.parent
        if (!value || !protocol) {
          return false
        }
        // TODO: move this to address-validator
        // This is a temporary solution to validate Hedera addresses
        // ref: https://github.com/hashgraph/hedera-sdk-js/blob/develop/src/account/AccountId.js#L63
        // JIRA task: https://appliedblockchain.atlassian.net/browse/ARX-254
        if (protocol === ChainProtocol.Hedera) {
          const accountIdRegex = /^(\d+)(?:\.(\d+)\.([a-fA-F0-9]+))?(?:-([a-z]{5}))?$/
          return accountIdRegex.test(value)
        }
        return isValidAddress(value, protocol)
      })
      .required(),
    protocol: Yup.string().required(),
  })
  .required()

const initialValues = { name: '', address: '', protocol: '' }

function AddAddressForm({ traderId, onSuccess }: AddAddressProps) {
  const { mutate } = useMutation(
    (data: AddTraderAddressOperationData) => createOperation(OperationType.AddTraderAddress, data, undefined, traderId),
    {
      onSuccess: () => {
        toast.success('Add address request sent for approval')
      },
      onError: (error: AxiosError<APIErrorResponse>) => {
        toast.error(error.response!.data.error.message || 'there was an error')
      },
    },
  )

  const onSubmit = async (data: AddressCreationFormData) => {
    try {
      const operationData = {
        addressName: data.name,
        address: data.address,
        protocol: data.protocol,
      }
      mutate(operationData)
      onSuccess()
    } catch (error: any) {}
  }

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<AddressCreationFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues as any,
    resolver: yupResolver(validationSchema),
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField name="name" control={control} label="New address name" />
      <Select
        name="protocol"
        control={control}
        label="Protocol"
        options={[
          { label: READABLE_PROTOCOL[ChainProtocol.Algorand], value: ChainProtocol.Algorand },
          { label: READABLE_PROTOCOL[ChainProtocol.Ethereum], value: ChainProtocol.Ethereum },
          { label: READABLE_PROTOCOL[ChainProtocol.Hedera], value: ChainProtocol.Hedera },
          { label: READABLE_PROTOCOL[ChainProtocol.Polygon], value: ChainProtocol.Polygon },
        ]}
      />
      <TextField name="address" control={control} label="New address" />
      <ButtonGroup fullWidth>
        <Button
          disabled={!isDirty || !isValid || isSubmitting}
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          fullWidth
        >
          Add address
        </Button>
      </ButtonGroup>
    </form>
  )
}
export default AddAddressForm
