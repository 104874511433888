import { UserRole, User } from '@archax/shared-types'

const hasOneOfRoles = (roles: UserRole[], user: User | null) => {
  if (!user) {
    return false
  }
  return roles.some((role) => user.roles.includes(role))
}

const hasUserRole = (user: User | null) => hasOneOfRoles([UserRole.User], user)

const hasAdminRole = (user: User | null) => hasOneOfRoles([UserRole.Admin], user)

export { hasOneOfRoles, hasUserRole, hasAdminRole }
