import isValidAlgorandAddress from './algorand'
import isValidEthereumAddress from './evm-address'
import { isValidHederaAddress } from './hedera'
import { ChainProtocol } from '@archax/shared-types'

export function isValidAddress(value: string, protocol: ChainProtocol) {
  if (protocol === ChainProtocol.Algorand) {
    return isValidAlgorandAddress(value)
  }
  if ([ChainProtocol.Ethereum, ChainProtocol.Polygon].includes(protocol)) {
    return isValidEthereumAddress(value)
  }
  if (protocol === ChainProtocol.Hedera) {
    return isValidHederaAddress(value)
  }

  throw new Error('Invalid protocol')
}
