import { createTheme, ThemeOptions } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle3: React.CSSProperties
    subtitle4: React.CSSProperties
    inputLabel: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties
    subtitle4?: React.CSSProperties
    inputLabel?: React.CSSProperties
  }

  interface Palette {
    alternative: Palette['primary']
  }

  interface PaletteOptions {
    alternative?: PaletteOptions['primary']
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true
    subtitle4: true
    inputLabel: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    alternative: true
  }
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#0070B8',
    },
    secondary: {
      main: '#0070B8',
    },
    alternative: {
      main: '#FFFFFF',
    },
    background: {
      paper: '#F5F7F7',
      default: '#FFFFFF',
    },
    info: {
      main: '#2196F3',
      light: '#D6EBFB',
      dark: '#0960A5',
    },
    text: {
      primary: '#131516',
      secondary: 'rgba(22, 25, 27, 0.5)',
    },
    success: {
      main: '#00A96B',
      light: '#C9F1E2',
      dark: '#00613D',
    },
    warning: {
      main: '#FAD961',
      light: '#FDF1C4',
      dark: '#BC9506',
    },
    error: {
      main: '#FF224A',
      light: '#FED6DD',
      dark: '#BD0022',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '125%',
      letterSpacing: '1px',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: '125%',
      letterSpacing: '0.8px',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '125%',
      letterSpacing: '0.6px',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.375rem',
      lineHeight: '125%',
      letterSpacing: '0.4px',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '125%',
      letterSpacing: '0.2px',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '120%',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: '150%',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1.125rem',
      lineHeight: '150%',
    },
    subtitle3: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '0.75rem',
      lineHeight: '100%',
      letterSpacing: '0.4px',
      color: 'rgba(22, 25, 27, 0.5)',
    },
    subtitle4: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '150%',
      color: '#131516',
    },
    body1: {
      fontWeight: '700',
      fontSize: '1rem',
      lineHeight: '150%',
    },
    body2: {
      fontWeight: '400',
      fontSize: '1rem',
      lineHeight: '1.5',
    },
    caption: {
      fontWeight: '400',
      fontSize: '0.75rem',
      lineHeight: '100%',
    },
    button: {
      textTransform: 'none',
    },
    inputLabel: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      lineHeight: '100%',
      fontSize: '0.75rem',
      letterSpacing: '0.4px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#13103E',
          backgroundImage: `linear-gradient(to right, #13103E, #0070B8)`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#F8FBFD',
        },
      },
    },
  },
}

export default createTheme(themeOptions)
